import React from 'react';
import {
	Switch,
	Route,
} from "react-router-dom";
import Details from './detail';

export default function App() {
	return (
		<div className="App">
			<Switch>
				<Route path="/user/:id" component={ Details } />
			</Switch>
		</div>
	);
};