import React, { useState } from 'react'
import axios from 'axios'
import {useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';

import style from '../../../styling/Panels.module.css';
import SectionHeader from '../../../components/sectionheader'

import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const SCAFFOLD_NBOT = gql`
  	mutation scaffoldNBot($id: ID, $consumption_url: String!) {
		scaffoldNBot(
			id: $id,
			consumption_url: $consumption_url
		) {
            id
        }
  	}
`;

function Toast(message) {
	toast.success(message);
}

function Component(props) {
	const [scaffoldNbot, {scaffoldNbotData}] = useMutation(SCAFFOLD_NBOT);

	const handleSubmit = e => {
		e.preventDefault();


		axios.post('http://5ea3eece.ngrok.io/scaffold', {
			nbot_id: props.id
		})
			.then(response => {				
				scaffoldNbot({ 
					variables: {
						id: props.id,
						consumption_url: response.data.consumption_url
					}
				})
				
			})
			.catch(error => {
				console.log(error)
			})
	}

	return (
		<>
			<SectionHeader title='Scaffold Camera' />
			<div className={ style.table_wrapper }>
				<button onClick={handleSubmit} className={ style.td_button + ' ' + style.submit_text } style={{ marginLeft: 0, paddingLeft: 0, marginRight: 0, paddingRight: 0 }}>Scaffold</button>
			</div>
		</>
	)
}

export default Component