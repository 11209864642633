import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import style from '../../../styling/Panels.module.css';
import SectionHeader from '../../../components/sectionheader';
import { Redirect } from 'react-router-dom'
import "react-toastify/dist/ReactToastify.css";
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

const GET_STATUS = gql`
    query nBotStatus($nbot_id: ID!){
        nBotStatus(id: $nbot_id){
            online_timestamp,
            online_status,
            last_checked,
            uptime,
            memory,
            cpu,
            disk_space
        }
    }
`;

const customStyles = {
	content : {
	    top                   : '45%',
	    left                  : '50%',
	    right                 : 'auto',
	    bottom                : 'auto',
	    marginRight           : '-50%',
	    transform             : 'translate(-50%, -50%)',
	    border  			  : '1px solid #878787',
		padding				  : 0,
		width                 : '70%',
		boxShadow             : '0px 8px 15px rgba(181, 181, 181, 0.8)',
    },
    online: {
        border: '1px solid #4AC948', 
        backgroundColor: '#4AC948',
        paddingLeft: '10px',
        borderRadius: '8px',
    },
    timeout: {
        border: '1px solid #ff3333', 
        backgroundColor: '#ff3333',
        paddingLeft: '10px',
        borderRadius: '8px',
    }
};


function ProgressBar (props){
    const {score} = props;

    //function for calculating the color
    const calcColor = (percent, start, end) => {
        let a = percent / 100,
        b = (end - start) * a,
        c = b + start;

        // return an CSS hsl color string
        return "hsl(" +  c + ", 100%, 50%)"
    }
    return (
        <CircularProgressbar 
            value={score}
            text={`${score} %`}
            circleRatio={0.7}
            styles={{
                trail: {
                    strokeLinecap: "butt",
                    transform: "rotate(-126deg)",
                    transformOrigin: "center center"
                },
                path: {
                    strokeLinecap: "butt",
                    transform: "rotate(-126deg)",
                    transformOrigin: "center center",
                    stroke: calcColor(score, 120, 0),
                },
                text: {
                    fill: "black",
                    fontSize: "15px"
                }
            }}
            strokeWidth={10}
        />
    )
}
export default function StatusNbot (props){
    const { data, loading, error } = useQuery(
        GET_STATUS, 
        { 
			variables: { nbot_id: props.id },
			pollInterval: 1000 * 1
		})
    if (loading) {
		return (
			<div>
				<h3>Loading</h3>
			</div>
		);
	}else if (error) {
		return (
			<Redirect to='/login' />
		)
	}else {
		return (
			<div>
				<SectionHeader title='Camera Status' />
                    <div className={ style.table_wrapper }>
                        <div className={ style.status_wrapper}>
                            <div className={ style.status_wrapper_component}>
                                <label className={style.status_wrapper_component_label}>Online Status</label>
                                <a style={(data.nBotStatus.online_status === "true") ? customStyles.online: customStyles.timeout }></a>
                            </div>
                            {(data.nBotStatus.online_status === "true") ?
                                (
                                    <div className={ style.status_wrapper_component}>
                                        <label className={style.status_wrapper_component_label}>Last Checked</label>
                                        <a className={style.status_wrapper_component_label}>{data.nBotStatus.last_checked}</a>
                                    </div>
                                ) : 
                                (
                                    <div className={ style.status_wrapper_component}>
                                        <label className={style.status_wrapper_component_label}>Offline Since</label>
                                        <a className={style.status_wrapper_component_label}>{data.nBotStatus.last_checked}</a>
                                    </div>)
                            }
                            {(data.nBotStatus.online_status === "true") ?
                            (<div className={ style.status_wrapper_component}>
                                <label className={style.status_wrapper_component_label}>Uptime</label>
                                <a className={style.status_wrapper_component_label}>{data.nBotStatus.uptime}</a>
                            </div>) : (<></>)}
                        </div> 
                        <br />
                        {(data.nBotStatus.online_status === "true") ?
                        (<div className={ style.status_wrapper}>
                                <div className={style.status_wrapper_component}>
                                    <div className={style.status_wrapper_component_round}>
                                        <ProgressBar score={data.nBotStatus.cpu}/>
                                        <span className={style.status_wrapper_component_span}>CPU</span>
                                    </div>       
                                </div>
                                <div className={style.status_wrapper_component}>
                                    <div className={style.status_wrapper_component_round}>
                                        <ProgressBar score={data.nBotStatus.memory}/>
                                        <span className={style.status_wrapper_component_span}>Memory</span>
                                    </div>     
                                </div>
                                <div className={style.status_wrapper_component}>
                                    <div className={style.status_wrapper_component_round}>
                                        <ProgressBar score={data.nBotStatus.disk_space} name="disk_space"/>
                                        <span className={style.status_wrapper_component_span}>Disk Space</span>
                                    </div>     
                                </div>
                        </div>) : (<></>)}     
                </div>
			</div>
		);
	}
}