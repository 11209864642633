import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import style from '../../../styling/Panels.module.css'
import Modal from 'react-modal'
import SectionHeader from '../../../components/sectionheader'
import { toast } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css"
import { useParams } from 'react-router-dom'

Modal.setAppElement('#root')

const UPDATE_PASSWORD = gql`
  	mutation updatePassword($id: ID!, $password: String!) {
		updatePassword(
	  		id: $id, 
	  		password: $password
		) {
            token
        }
  	}
`;

function ErrorToast(message) {
	toast.error(message)
}

const customStyles = {
	content : {
	    top                   : '30%',
	    left                  : '50%',
	    right                 : 'auto',
	    bottom                : 'auto',
	    marginRight           : '-50%',
	    transform             : 'translate(-50%, -50%)',
	    border				  : '1px solid #878787',
		padding				  : 0,
		boxShadow             : '0px 8px 15px rgba(181, 181, 181, 0.8)',
	}
};

export default function EditStudentModal (props) {
	const { id } = useParams()
	const [ modalIsOpen, setIsOpen ] = React.useState(false);
    
	function openModal() {
	  setIsOpen(true);
	}

	function closeModal () {
		setIsOpen(false);
	}
    
	const [ password, setPassword ] = useState('')
	const [ passwordRepeat, setPasswordRepeat ] = useState('')

	const [updatePassword] = useMutation(UPDATE_PASSWORD, {
		onError(e) {
			ErrorToast("Could not update password")
			console.log(e)
		},
	})

	const handleSubmit = e => {
		if (password === passwordRepeat) {
			updatePassword({
				variables: {
					id,
					password
				}
			})
		}
		else {
			e.preventDefault()
			ErrorToast("Passwords don't match.")
		}
	}

	return (
		<>
			<SectionHeader title='Update Password' />
			<div className={ style.table_wrapper }>
				<button onClick={ openModal } className={ style.td_button + ' ' + style.submit_text }>Update</button>
				<Modal
					isOpen={modalIsOpen}
					onRequestClose={closeModal}
					contentLabel="Update Account"
					style={ customStyles }
				>
					<div className={ style.panel_row } style={{ marginBottom: 0 }}>
						<div className={ style.panel + ' ' + style.panel_modal }>
							<h3>Update Password</h3>
							<form onSubmit={ handleSubmit }>
								<table>
									<thead>
										<tr>
											<th>Password</th>
											<td><input type="password" value={ password } onChange={ e => setPassword(e.target.value) } /></td>
										</tr>
										<tr>
											<th>Re-type Password</th>
											<td><input type="password" value={ passwordRepeat } onChange={ e => setPasswordRepeat(e.target.value) } /></td>
										</tr>
									</thead>
								</table>
								<div style={{ margin: 'auto', width: '50%'}}>
									<div style={{ display: 'flex'}}>
										<button className={ style.form_button } onClick={closeModal}>Cancel</button>
										<button className={ style.form_button } type="submit">Update</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</Modal>
			</div>
		</>
	);
}