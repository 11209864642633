import React from 'react'
import {Link} from "react-router-dom"
import style from '../../../styling/Panels.module.css'

import EditLecture from './EditLecture'
import DeleteLecture from './DeleteLecture'
import VideoModal from './VideoModal'
import Cookies from 'universal-cookie';

var moment = require('moment-timezone');
moment().format();
moment.defaultFormat = "MMMM Do YYYY, h:mm:ss a";

/**
 * @return {null}
 */
function Lecture (props) {
	const cookies = new Cookies()
	return (
		<tr>
			<td><Link to={`/classrooms/${props.lecture.classroom.id}`}>{props.lecture.classroom.name}</Link></td>
			<td>{moment(props.lecture.start_time).format()}</td>
			<td>{moment(props.lecture.end_time).format()}</td>
			<td>
				{
					props.lecture.classroom && props.lecture.classroom.nBot && props.lecture.classroom.nBot.url 
						? props.lecture.end_time <= Date.now()
							? 'Class stream over'
							: props.lecture.start_time >= Date.now()
								? 'Class not started'
								: <VideoModal url={props.lecture.classroom.nBot.url} />
						: 'No stream found'
				}
			</td>
			{
				cookies.get('x-role') && cookies.get('x-role') === 'NOTETAKER'
					? null
					:
					<>
						<td><EditLecture class_id={props.class_id} lecture={props.lecture} /></td>
						<td><DeleteLecture lecture_id={props.lecture.id} /></td>
					</>
			}
		</tr>
	)
}

function Component(props) {
	const cookies = new Cookies()
	return (
		<div>
			<h3>Lectures</h3>
			<div className={ style.table_wrapper }>
				<table>
					<thead>
						<tr>
							<th>Classroom</th>
							<th>Lecture Start</th>
							<th>Lecture End</th>
							<th />
							{
								cookies.get('x-role') && cookies.get('x-role') === 'NOTETAKER' ?
									null :
									<>
										<th />
										<th />
									</>
							}
						</tr>
					</thead>
					<tbody>
						{
							props.lectures.length > 0 ?
								props.lectures.map(lecture => (
									<Lecture key={lecture.id} lecture={lecture} class_id={props.class_id} />
								)) :
								<tr>
									<td>N/A</td>
									<td>N/A</td>
									<td>N/A</td>
									<td>N/A</td>
								</tr>
						}
					</tbody>
				</table>
			</div>
		</div>
	)}

export default Component;