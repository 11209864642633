import React from 'react'
import { Route, Switch, Link } from "react-router-dom"

import NBot from '../pages/nbot/App'
import Classroom from '../pages/classroom/App'
// import School from '../pages/school/App'
// import Student from '../pages/student/App'
import Class from '../pages/class/App'
import User from '../pages/user/App'
import Lecture from '../pages/lecture/App'
import Campus from '../pages/campus/App'
import Scanner from '../pages/scanner/App'

// import AdminSchool from '../pages/admin_panel/school/App'
// import AdminStudent from '../pages/admin_panel/student/App'
// import AdminTask from '../pages/admin_panel/task/App'

import '../styling/Main.module.css'
import style from '../styling/LayoutCommon.module.css'
import Cookies from 'universal-cookie'


const cookies = new Cookies()
var base64Url = ''
var base64 = ''
var me = ''

if (cookies.get('x-token')) {
	base64Url = cookies.get('x-token').split('.')[1]
	base64 = base64Url.replace('-', '+').replace('_', '/')
	me = JSON.parse(atob(base64))
}


// Sidebar Route List
const routes = [
	{
		path: "/nbots",
		sidebar_title: "Cameras",
		component: () => <NBot />
	},
	{
		path: "/classrooms",
		sidebar_title: "Classrooms",
		component: () => <Classroom />
	},
	{
		path: "/classes",
		sidebar_title: "Classes",
		component: () => <Class />
	},
	{
		path: "/lectures",
		sidebar_title: "Lectures",
		component: () => <Lecture />
	},
	{
		path: "/campus",
		sidebar_title: "Campus",
		component: () => <Campus />
	},
	{
		path: "/scanner",
		sidebar_title: "Scanner",
		component: () => <Scanner />
	},
	{
		path: "/user/" + me.id,
		sidebar_title: "Account",
		component: () => <User />
	},	
]

/*
	{
		path: "/nbots",
		sidebar_title: "nBots",
		component: () => <NBot />
	},
	{
		path: "/classrooms",
		sidebar_title: "Classrooms",
		component: () => <Classroom />
	},
	{
		path: "/classes",
		sidebar_title: "Classes",
		component: () => <Class />
	},
	{
		path: "/lectures",
		sidebar_title: "Lectures",
		component: () => <Lecture />
	},
	{
		path: "/user/" + me.id,
		sidebar_title: "Account",
		component: () => <User />
	},
	{
		path: "/admin/schools",
		sidebar_title: "AP: Schools",
		component: () => <AdminSchool />
	},
	{
		path: "/admin/students",
		sidebar_title: "AP: Students",
		component: () => <AdminStudent />
	},
	{
		path: "/admin/tasks",
		sidebar_title: "AP: Tasks",
		component: () => <AdminTask />
	}
*/
function SidebarLink (props) {
	return (
		<li><Link to={ props.link }>{ props.sidebar_title }</Link></li>
	)
}

function SidebarList () {
	const cookies = new Cookies()

	if (cookies.get('x-role') && cookies.get('x-role') === 'NOTETAKER') {
		return (
			<ul className={ style.sidebar_list }>
				<SidebarLink key={ 0 } link={ '/classes' } sidebar_title={ 'Classes' } />
				<SidebarLink key={ 1 } link={ '/user/' + me.id } sidebar_title={ 'Account' } />
			</ul>
		)
	}else if (cookies.get('x-role') && cookies.get('x-role') === 'INSTALLER'){
		return (
			<ul className={ style.sidebar_list }>
				<SidebarLink key={ 0 } link={ '/nbots' } sidebar_title={ 'Cameras' } />
				<SidebarLink key={ 0 } link={ '/classrooms' } sidebar_title={ 'Classrooms' } />
				<SidebarLink key={ 1 } link={ '/campus' } sidebar_title={ 'Campus' } />
				<SidebarLink key={ 2 } link={ '/user/' + me.id } sidebar_title={ 'Account' } />
			</ul>
		)
	}else {
		return (
			<ul className={ style.sidebar_list }>
				{routes.map((route, index) => (
					<SidebarLink key={ index } link={ route.path } sidebar_title={ route.sidebar_title } />
				))}
			</ul>
		)
	}
}

function AppSpace () {
	return (
		<div style={{ flex: 1, padding: "0", margin: "0" }}>
			<Switch>
				{routes.map((route, index) => (
					<Route
						key={ index }
						path={ route.path }
						exact={ route.exact }
						children={ route.component }
					/>
				))}
			</Switch>
		</div>
	)
}

export default function Sidebar () {
	return (
		<div style={{ display: "flex" }}>
			<div className={ style.sidebar }>
				<SidebarList />
			</div>
			<AppSpace />
		</div>
	)
}