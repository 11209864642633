import React, { useState } from 'react';
import {
	useParams, Redirect
} from "react-router-dom";
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PageHeader from '../../components/pageheader';
import AssignNBot from './components/AssignNbot';
import VideoModal from './components/VideoModal';
import NbotInfo from './components/NbotInfo';
import DeleteNbot from './components/DeleteNbot';
import GenerateScaffolding from './components/GenerateScaffolding'
import style from '../../styling/Panels.module.css';
import StatusNbot from './components/StatusNbot';
import ControlsNbot from './components/ControlsNbot';


const GET_NBOT = gql`
  	query nBot($nbot_id: ID!) {
		nBot(id: $nbot_id) {
			id
			password
			hardware_number
			url
			status
			angle
			classroom {
				id
				name
				campus{
					id
					name
				}
			}
		}
	}
`;

export default function App() {
	let { id } = useParams();
	const { data, loading, error } = useQuery(
		GET_NBOT,
		{ 
			variables: { nbot_id: id },
			pollInterval: 1000 * 5
		}
	);

	if (loading) {
		return (
			<div>
				<PageHeader title="Loading" />
			</div>
		);
	}
	else if (error) {
		return (
			<Redirect to='/login' />
		)
	}
	return (
		<div>
			<PageHeader title={ "Launch Camera " + id } />

			<div className={ style.panel_row }>
				<div className={ style.panel + ' ' + style.panel_single }>
					<NbotInfo nbot={ data.nBot } />
				</div>
			</div>

			<div className={ style.panel_row }>
				<div className={ style.panel + ' ' + style.panel_single }>
					<AssignNBot id={ data.nBot.id } classAssignment={ data.nBot.classroom }/>
				</div>

				{/* <div className={ style.panel + ' ' + style.panel_double }>
					<VideoModal url={ data.nBot.url } />
				</div> */}
			</div>

			<div className={ style.panel_row }>
				<div className={ style.panel + ' ' + style.panel_single }>
					<ControlsNbot id={ data.nBot.id } classAssignment={ data.nBot.classroom }/>
				</div>
			</div>

			<div className={ style.panel_row }>
				<div className={ style.panel + ' ' + style.panel_single }>
					<StatusNbot id={ data.nBot.id } />
				</div>
			</div>

			{
				/*
						<div className={ style.panel_row }>
							<div className={ style.panel + ' ' + style.panel_single }>
								<GenerateScaffolding id={ data.nBot.id } />
							</div>
						</div>
					*/
			}
			<div>
				<DeleteNbot nbot={ data.nBot } />
			</div>
		</div>
	);
}