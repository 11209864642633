import React, { useState } from 'react';
import style from '../../../styling/Panels.module.css';
import Modal from 'react-modal';
import VideoPlayer from '../../../components/videoplayer';
import SectionHeader from '../../../components/sectionheader';

Modal.setAppElement('#root')


const customStyles = {
	content : {
	    top                   : '45%',
	    left                  : '50%',
	    right                 : 'auto',
	    bottom                : 'auto',
	    marginRight           : '-50%',
	    transform             : 'translate(-50%, -50%)',
	    border  			  : '1px solid #878787',
		padding				  : 0,
		width                 : '70%',
		boxShadow             : '0px 8px 15px rgba(181, 181, 181, 0.8)',
	}
};

export default function EditStudentModal (props) {
	const [ modalIsOpen, setIsOpen ] = React.useState(false);
    
	function openModal() {
	  setIsOpen(true);
	}

	function closeModal () {
		setIsOpen(false);
	}

	const [url] = useState(props.url ? props.url : '');

	return (
		<>
			<SectionHeader title='nBot Livefeed' />
			<div className={ style.table_wrapper }>
				<button onClick={ openModal } className={ style.td_button + ' ' + style.submit_text } style={{ marginLeft: 0, paddingLeft: 0, marginRight: 0, paddingRight: 0 }}>View Stream</button>
			</div>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel="nBot Livefeed"
				style={ customStyles }
			>
				<div className={ style.panel_row } style={{ marginBottom: 0 }}>
					<div className={ style.panel + ' ' + style.panel_modal_video }>
						<VideoPlayer src={ url } controls={ true } autoplay={ false } />
					</div>
				</div>
			</Modal>
		</>
	);
}