import React, { useState } from 'react'

import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import style from '../../../styling/Panels.module.css'
import SectionHeader from '../../../components/sectionheader'

import { useMutation } from '@apollo/react-hooks'
import { toast } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css"
import { Redirect } from 'react-router-dom'

const GET_CLASSROOMS = gql`
  query classroom_list {
      classrooms {
			id
			name
      }
  }
`

const PUT_CAMPUS = gql`
mutation assignClassroomToCampus($campus_id: ID!, $classroom_id: ID!)  {
	assignClassroomToCampus(campus_id: $campus_id, classroom_id: $classroom_id)
	{
	  id
	}
}
`

/**
 * @return {null}
 */
function UnassignedNbot(props) {
	if (props.classroom !== null) {
		return <option value={ props.classroom.id }>{ props.classroom.name }</option>
	}
	return null
}

function Toast(message) {
	toast.success(message)
}
function WarningToast(message) {
	toast.warning(message)
}

export default function AssignNBot(props) {
	const [classroom, setClassroom] = useState(-1)

	const { data, loading, error } = useQuery(GET_CLASSROOMS, {
		pollInterval: 1000 * 15,
	})
	const [put_campus] = useMutation(PUT_CAMPUS, {
		onCompleted(r) {
			Toast("Classrom Assigned to Campus")
		}
    })
    

	const handleSubmit = e => {
        e.preventDefault()
		if (classroom && classroom !== -1) {
			put_campus({ 
				variables: {
					campus_id: props.id,
					classroom_id: classroom
				}
			})
		}
		else {
			WarningToast("Select Classroom")
		}

	}


	if (loading) {
		return (
			<div>
				<h3>Loading</h3>
			</div>
		);
	}
	else if (error) {
		return (
			<Redirect to='/login' />
		)
	}    
	else {
		return (
			<div>
				<SectionHeader title='Add Classroom' />
				<form onSubmit={ handleSubmit }>
					<div style={{ margin: '0 auto', display: 'block', width: '100%' }}>
						<select id='assign-nbot' value={ classroom } style={{ marginLeft: 0 }} onChange={ e => setClassroom(Number(e.target.value)) } >
							<option value={ -1 }>{ "Unassigned Classroom" }</option>
							{ data.classrooms.map(classroom => (
								<UnassignedNbot classroom={ classroom } key={ classroom.id } />
							)) }
						</select>
						<button type="submit" value="Submit" className={ style.td_button + ' ' + style.submit_text }>Add</button>
					</div>
				</form>
			</div>
		);
	}
}