import React from 'react';
import { Link } from 'react-router-dom';

import style from '../styling/LayoutCommon.module.css';

export default function Header () {
	return (
		<div className={ style.header } >
			<p><Link to="/classes">Note Taking Express</Link></p> 
			<hr />
		</div>
	)
}