import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom"
import panelStyle from '../../../styling/Panels.module.css'
import chatStyle from '../../../styling/Chat.module.css'
import _ from 'lodash'
var StompJs = require('@stomp/stompjs')

function Poll(props) {
	const [question, setQuestion] = useState(props.answers && props.answers[0] ? props.answers[0].question.question : null)
	const [answers, setAnswers] = useState()

	useEffect(() => {
		if (props.answers && props.answers[0]) {
			setQuestion(props.answers[0].question)
		}
	})

	useEffect(() => {
		if (props.answers) {
			setAnswers(props.answers)
		}
	}, [props.answers])

	if (question) {
		return (
			<>
				<p>{question.question}</p>
				{question.answers.map((qAnswer, index) => (
					<p key={index}>{qAnswer + ': ' + 	
					_.sum(
						_.map(answers, function(answer) {
							if (answer.answer == qAnswer) {
								return 1
							}
							else {
								return 0
							}
						})
					)}
					</p>
				))}
			</>
		)
	}
	else {
		return null
	}
}

function Component() {
	const {id} = useParams()
	const [firstRender, setFirstRender] = useState(true)
	const [answers, setAnswers] = useState([])
	const [latestAnswer, setLatestAnswer] = useState()
	
	const client = new StompJs.Client({
		brokerURL: "ws://localhost:15674/ws",
		connectHeaders: {
		  login: "guest",
		  passcode: "guest"
		},
		debug: function (str) {
		  // console.log(str);
		},
		reconnectDelay: 5000,
		heartbeatIncoming: 4000,
		heartbeatOutgoing: 4000
	});

	useEffect(() => {
		if (firstRender) {
			client.onConnect = function(frame) {
				const answerSub = client.subscribe("/topic/georgebrown." + id + ".polls.answers", function(message) {
					try {
						let newPoll = JSON.parse(message.body)
						if (!latestAnswer) {
							setLatestAnswer(newPoll)
						}
					}
					catch (e) {
						console.error(e)
					}

				})
			}
			setFirstRender(false)
		}
	})

	useEffect(() => {
		if (latestAnswer) {
			if (answers[0] && latestAnswer.question.id != answers[0].question.id) {
				const messageList = [latestAnswer]
				setAnswers(messageList)
			}
			else {
				const messageList = [...answers, latestAnswer]
				setAnswers(messageList)
			}
		}
		setLatestAnswer()
	}, [latestAnswer])

	client.onStompError = function (frame) {
		// Will be invoked in case of error encountered at Broker
		// Bad login/passcode typically will cause an error
		// Complaint brokers will set `message` header with a brief message. Body may contain details.
		// Compliant brokers will terminate the connection after any error
		console.log('Broker reported error: ' + frame.headers['message']);
		console.log('Additional details: ' + frame.body);
	};

	client.activate();

	return (
		<div className={panelStyle.panel + ' ' + panelStyle.panel_triple + ' ' + chatStyle.chatbox}>
			<h2>Poll Answers</h2>
			<div className={chatStyle.message_panel}>
				{
					answers 
						? <Poll answers={answers} />
						: null
				}
			</div>
		</div>
	)
}

export default Component