import React from 'react';
import style from '../../../styling/Panels.module.css';
import EditNbot from './EditNbot'
import {
	Link
} from "react-router-dom";

var moment = require('moment');
moment().format();
moment.defaultFormat = "MMMM Do YYYY, h:mm:ss a";

export default function NbotInfo (props) {
	return (
		<div style={{ display: 'flex', alignItems: "center" }}>
			<div className={ style.table_wrapper } style={{ width: "100%" }}>
				<table>
					<thead>
						<tr>
							<th>Classroom</th>
							<th>Campus</th>
							<th>Angle</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{
									props.nbot.classroom ?
										<Link to={`/classrooms/${ props.nbot.classroom.id }`}>{ props.nbot.classroom.name }</Link> :
										"N/A"
								}
							</td>
							<td>{
									(props.nbot.classroom !== null) ? ((props.nbot.classroom.campus !== null) ?
										((<Link to={`/campus/${ props.nbot.classroom.campus.id }`}>{ props.nbot.classroom.campus.name }</Link> )):
										"N/A") : "N/A"
								}
							</td>
							<td>{ props.nbot.angle ?  props.nbot.angle : "N/A" }</td>
						</tr>
					</tbody>
				</table>
			</div>
			<EditNbot nbot={ props.nbot } usage={'Edit'} />
		</div>
	);
}