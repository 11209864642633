import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom"
import panelStyle from '../../../styling/Panels.module.css'
import chatStyle from '../../../styling/Chat.module.css'
var StompJs = require('@stomp/stompjs');

function Component() {
	const {id} = useParams()
	const [firstRender, setFirstRender] = useState(true)
	const [messages, setMessages] = useState(["Connecting to chat..."])
	const [latestMessage, setLatestMessage] = useState()
	const [message, setMessage] = useState('')
	const [stateClient, setStateClient] = useState();
	const [firstConnection, setFirstConnection] = useState(true);

	const client = new StompJs.Client({
		brokerURL: "ws://18.216.88.45:15674/ws",
		connectHeaders: {
		  login: "student",
		  passcode: "student"
		},
		debug: function (str) {
		  // console.log(str);
		},
		reconnectDelay: 5000,
		heartbeatIncoming: 4000,
		heartbeatOutgoing: 4000
	});

	useEffect(() => {
		if (firstConnection && stateClient) {
			const messageList = [...messages, "Connected to chat."];
			setMessages(messageList);
			setFirstConnection(false);
		}
	}, [stateClient])

	useEffect(() => {
		if (firstRender) {
			if (!client.active) {
				client.activate();
				setStateClient(client);
			}

			client.onConnect = function(frame) {
				const messageSub = client.subscribe("/topic/georgebrown." + id + ".messages", function(message) {
					let newMessage = JSON.parse(message.body)
					if (!latestMessage) {
						setLatestMessage(newMessage.message)
					}
				})
			}
			setFirstRender(false)
		}
	}, [firstRender])

	useEffect(() => {
		if (latestMessage) {
			const messageList = [...messages, latestMessage]
			setMessages(messageList)
		}
		setLatestMessage()
	}, [latestMessage])

	function SendMessage() {
		if (message !== '' && stateClient) {
			try {
				stateClient.publish({destination: '/topic/georgebrown.' + id + '.messages', body: 
					JSON.stringify({
						'type': 'message',
						'id': Math.floor(Math.random() * 100),
						'message': message,
					})
				})
				setMessage('')
			}
			catch (e) {
				console.error(e)
			}
		}
	}

	client.onStompError = function (frame) {
		// Will be invoked in case of error encountered at Broker
		// Bad login/passcode typically will cause an error
		// Complaint brokers will set `message` header with a brief message. Body may contain details.
		// Compliant brokers will terminate the connection after any error
		console.log('Broker reported error: ' + frame.headers['message']);
		console.log('Additional details: ' + frame.body);
	};

	const handleSubmit = e => {
		e.preventDefault()
		SendMessage()
	}

	return (
		<div className={panelStyle.panel + ' ' + panelStyle.panel_triple + ' ' + chatStyle.chatbox}>
			<h2>Messages</h2>
			<div className={chatStyle.message_panel}>
				{messages
					? messages.map((message, index) => (
						<p key={index}>{message}</p>
					))
					: null
				}
			</div>
			<form onSubmit={handleSubmit}><input autoComplete="off" placeholder="Type here..." value={message} onChange={e => setMessage(e.target.value)} /><button>Submit</button></form>
		</div>
	)
}

export default Component