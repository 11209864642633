import React from 'react';
import { Redirect } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import style from '../../../styling/Panels.module.css';
import Modal from 'react-modal';

import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

Modal.setAppElement('#root')

const DELETE_CLASSROOM = gql`
  	mutation delete_classroom($id: ID!) {
		deleteClassroom(
            id: $id
		) {
			id
		}
  	}
`;

const customStyles = {
	content : {
	    top                   : '30%',
	    left                  : '50%',
	    right                 : 'auto',
	    bottom                : 'auto',
	    marginRight           : '-50%',
	    transform             : 'translate(-50%, -50%)',
	    border				  : '1px solid #878787',
		padding				  : 0,
		boxShadow             : '0px 8px 15px rgba(181, 181, 181, 0.8)',
	}
};

function Toast(message) {
	toast.success(message);
}

export default function DeleteStudentModal (props) {
	const [ modalIsOpen, setIsOpen ] = React.useState(false);
	const [ redirect, setRedirect ] = React.useState(false);

	function openModal() {
	  setIsOpen(true);
	}

	function closeModal () {
		setIsOpen(false);
	}
    
	const [delete_classroom] = useMutation(DELETE_CLASSROOM);

	const handleSubmit = e => {
		e.preventDefault();
		delete_classroom({ 
			variables: {
				id: props.classroom.id
			}
		});
		Toast("Classroom Deleted");
		return setRedirect(true);
	}

	return (
		<>
			<div style={{ display:'block'}}>
				<button onClick={ openModal } className={ style.standalone_button + ' ' + style.warning_text }>Delete</button>
				<Modal
					isOpen={ modalIsOpen }
					onRequestClose={ closeModal }
					contentLabel="Delete Classroom"
					style={ customStyles }
				>
					<div className={ style.panel_row } style={{ marginBottom: 0 }}>
						<div className={ style.panel + ' ' + style.panel_modal }>
							<form onSubmit={ handleSubmit }>
								<div style={{ margin: 'auto'}}>
									<h3>Delete Classroom</h3>
									<div style={{ display: 'flex'}}>
										<button className={ style.form_button + ' ' + style.submit_text } onClick={closeModal}>Cancel</button>
										<button className={ style.form_button + ' ' + style.warning_text } type="submit">Delete</button>
									</div>
								</div>
							</form>
						</div>
					</div>
					{ redirect ? <Redirect to="/classrooms" /> : null }
				</Modal>
			</div>
		</>
	);
}