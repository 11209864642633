import React from 'react';
import {
	Link
} from "react-router-dom";
import style from '../../../styling/Panels.module.css';
import EditClassroom from './EditCreateClassroom'

var moment = require('moment');
moment().format();
moment.defaultFormat = "MMMM Do YYYY, h:mm:ss a";

function NbotList (props) {
    return (
        <ul style={{listStyleType: "none", margin: "0px 0px", padding: "0px 0px"}}>
            {props.NbotList.nBots.map(nBot => 
                <li key={nBot.id}><a href={`/nBots/${nBot.id}`}>{ nBot.hardware_number }</a></li>          
            )}
        </ul>
    )
}

export default function ClassroomInfo (props) {
	return (
		<div style={{ display: 'flex', alignItems: "center" }}>
			<div className={ style.table_wrapper } style={{ width: "100%" }}>
				<table>
					<thead>
						<tr>
							<th>Classroom Name</th>
							<th>Launch Camera</th>
							<th>Campus</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td style={{verticalAlign: "top"}}>{ props.classroom.name }</td>
							<td style={{verticalAlign: "top"}}>
								{
                                    props.classroom.nBots ?
                                        (
                                        <NbotList NbotList = { props.classroom }/>
                                        )
										:
										"N/A"
								}
							</td>
							<td style={{verticalAlign: "top"}}>
								{
									props.classroom.campus ?
										<Link to={`/campus/${ props.classroom.campus.id }`}>{ props.classroom.campus.name }</Link> :
										"N/A"
								}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<EditClassroom classroom={ props.classroom } usage={'Edit'} />
		</div>
	);
}