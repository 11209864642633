import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom"
import panelStyle from '../../../styling/Panels.module.css'
import chatStyle from '../../../styling/Chat.module.css'
var StompJs = require('@stomp/stompjs')

function Poll(props) {
	const {id} = useParams()
	const [firstRender, setFirstRender] = useState(true)
	const [selected, setSelected] = useState(-1)
	const [pollID, setPollID] = useState()

	const client = new StompJs.Client({
		brokerURL: "ws://localhost:15674/ws",
		connectHeaders: {
		  login: "guest",
		  passcode: "guest"
		},
		debug: function (str) {
		  // console.log(str);
		},
		reconnectDelay: 5000,
		heartbeatIncoming: 4000,
		heartbeatOutgoing: 4000
	});

	useEffect(() => {
		if (firstRender) {
			client.onConnect = function(frame) {
			}
			setFirstRender(false)
		}
	})

	useEffect(() => {
		if (props.poll) {
			setPollID(props.poll.id)
		}
	})

	useEffect(() => {
		setSelected(-1)
	}, [pollID])

	client.activate()

	function SendAnswer(answer, index) {
		if (!firstRender) {
			if (client) {
				client.publish({destination: '/topic/georgebrown.' + id + '.polls.answers', 
					body: JSON.stringify({
						'type': 'answer',
						'question': props.poll,
						'answer': answer
					})
				})
			}
		}
		setSelected(index)
	}

	return (
		<>
			<code>{props.poll && props.poll.question ? props.poll.question : null}</code>
			{
				props.poll && props.poll.answers
					? props.poll.answers.map((answer, index) => (
						<button key={index} className={selected == index ? chatStyle.answerSelected : null}
							onClick={() => {
								SendAnswer(answer, index)
							}}
							disabled={selected > -1 ? true : false}
						>
							{answer}
						</button>
					))
					: null
			}
		</>
	)
}

function PollAnswer(props) {
	const {id} = useParams()
	const [firstRender, setFirstRender] = useState(true)
	const [selected, setSelected] = useState(false)

	const client = new StompJs.Client({
		brokerURL: "ws://localhost:15674/ws",
		connectHeaders: {
		  login: "guest",
		  passcode: "guest"
		},
		debug: function (str) {
		  // console.log(str);
		},
		reconnectDelay: 5000,
		heartbeatIncoming: 4000,
		heartbeatOutgoing: 4000
	});

	useEffect(() => {
		if (firstRender) {
			client.onConnect = function(frame) {
			}
			setFirstRender(false)
		}
	})

	client.activate();

	function SendAnswer() {
		client.publish({destination: '/topic/georgebrown.' + id + '.polls.answers', body: props.answer ? props.answer : 'no answer'})
		setSelected(true)
	}

	return (
		<>
			{
				props.answer
					? <button onClick={SendAnswer} className={selected ? chatStyle.answerSelected : null}>{props.answer}</button>
					: null
			}
		</>
	)
}

function Component() {
	const {id} = useParams()
	const [firstRender, setFirstRender] = useState(true)
	const [messages, setMessages] = useState([])
	const [latestMessage, setLatestMessage] = useState()

	useEffect(function persistMessages() {
		localStorage.setItem('messages', messages);
	});
	
	const client = new StompJs.Client({
		brokerURL: "ws://localhost:15674/ws",
		connectHeaders: {
		  login: "guest",
		  passcode: "guest"
		},
		debug: function (str) {
		  // console.log(str);
		},
		reconnectDelay: 5000,
		heartbeatIncoming: 4000,
		heartbeatOutgoing: 4000
	});

	useEffect(() => {
		if (firstRender) {
			client.onConnect = function(frame) {
				const messageSub = client.subscribe("/topic/georgebrown." + id + ".polls.questions", function(message) {
					try {
						let newPoll = JSON.parse(message.body)
						if (!latestMessage) {
							setLatestMessage(newPoll)
						}
					}
					catch (e) {
						console.error(e)
					}
				})
			}
			setFirstRender(false)
		}
	})

	useEffect(() => {
		if (latestMessage) {
			const messageList = [...messages, latestMessage]
			setMessages(messageList)
		}
		setLatestMessage()
	}, [latestMessage])

	client.onStompError = function (frame) {
		// Will be invoked in case of error encountered at Broker
		// Bad login/passcode typically will cause an error
		// Complaint brokers will set `message` header with a brief message. Body may contain details.
		// Compliant brokers will terminate the connection after any error
		console.log('Broker reported error: ' + frame.headers['message']);
		console.log('Additional details: ' + frame.body);
	};

	client.activate();

	return (
		<div className={panelStyle.panel + ' ' + panelStyle.panel_triple + ' ' + chatStyle.chatbox}>
			<h2>Poll</h2>
			<div className={chatStyle.message_panel}>
				{
					messages 
						? <Poll poll={messages[messages.length-1]} />
						: null
				}
			</div>
		</div>
	)
}

export default Component