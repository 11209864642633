import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import style from '../../../styling/Panels.module.css';
import Modal from 'react-modal';

import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

Modal.setAppElement('#root')

// const GET_SCHOOLS = gql`
//     query get_schools {
//         schools {
//             id
//             name
//         }
//     }
// `;
const PUT_CAMPUS = gql`
    mutation put_camppus($id: ID!, $name: String!) {
        updateCampus(
            id: $id,
            name: $name
        ) {
            id
        }
    }
`;
const POST_CAMPUS = gql`
    mutation post_campus($name: String!) {
        createCampus(
            name: $name
        ) {
            id
        }
    }
`;

function Toast(message) {
	toast.success(message);
}

const customStyles = {
	content : {
	    top                   : '30%',
	    left                  : '50%',
	    right                 : 'auto',
	    bottom                : 'auto',
	    marginRight           : '-50%',
	    transform             : 'translate(-50%, -50%)',
	    border                : '1px solid #878787',
		padding				  : 0,
		boxShadow             : '0px 8px 15px rgba(181, 181, 181, 0.8)',
	}
};

export default function EditStudentModal (props) {
	const [ modalIsOpen, setIsOpen ] = React.useState(false)
    
	function openModal() {
	  setIsOpen(true)
	}

	function closeModal () {
		setIsOpen(false)
	}

	function clearFields() {
		set_name()
	}

	const [ name, set_name ] = useState(props.campus ? props.campus.name : '')

	const [ redirect, setRedirect ] = React.useState(false)

	const [put_campus, { put_data } ] = useMutation(PUT_CAMPUS)
	const [post_campus, { post_data } ] = useMutation(POST_CAMPUS)

	const handleSubmit = e => {
		e.preventDefault();
		if (props.usage === 'Create') {
			post_campus({ 
				variables: {
					name: name
				}
			});
			Toast("Campus Created");
			closeModal();
			clearFields();
		}
		else {
			put_campus({ 
				variables: {
					id: props.campus.id,
					name: name
				}
			});
			Toast("Campus Edited");
			return setRedirect(true);
		}
	}

	return (
		<>
			{ 
				(props.usage === 'Create') ? 
					<button onClick={ openModal } className={ style.standalone_button }>{ props.usage }</button> :
					<button onClick={ openModal } className={ style.td_button + ' ' + style.submit_text }>{ props.usage }</button> 
			}
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel="Update Campus"
				style={ customStyles }
			>
				<div className={ style.panel_row } style={{ marginBottom: 0 }}>
					<div className={ style.panel + ' ' + style.panel_modal }>
						<h3>{ props.usage } Campus</h3>
						<form onSubmit={ handleSubmit }>
							<table>
								<thead>
									<tr>
										<th>Name</th>
										<td><input type="text" value={ name } onChange={ e => set_name(e.target.value) } /></td>
									</tr>
								</thead>
							</table>
							<div style={{ margin: 'auto', width: '50%'}}>
								<div style={{ display: 'flex'}}>
									<button className={ style.form_button } onClick={closeModal}>Cancel</button>
									<button className={ style.form_button } type="submit">{ props.usage }</button>
								</div>
							</div>
						</form>
					</div>
				</div>
				{ redirect ? <Redirect to={ "/campus" } /> : null }
			</Modal>
		</>
	);
}