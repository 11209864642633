import React, { useState } from 'react'

import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import style from '../../../styling/Panels.module.css'
import SectionHeader from '../../../components/sectionheader'

import { useMutation } from '@apollo/react-hooks'
import { toast } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css"
import { Redirect } from 'react-router-dom'

const GET_NBOTS = gql`
  query nbot_list {
      nBots {
			id
			hardware_number
			classroom {
				id
				name
			}
      }
  }
`

const PUT_NBOT = gql`
mutation assignNbotToClassroom($nbot_id: ID!, $classroom_id: ID!)  {
	assignNbotToClassroom(nbot_id: $nbot_id, classroom_id: $classroom_id)
	{
	  id
	}
}
`

// const DELETE_NBOT = gql`
// mutation deleteClassroomToNBot($nbot_id: ID!)  {
// 	deleteClassroomToNBot(nbot_id: $nbot_id)
// 	{
// 	  id
// 	}
// }
// `

/**
 * @return {null}
 */
function UnassignedNbot(props) {
	if (props.nbot.classroom === null) {
		return <option value={ props.nbot.id }>{ props.nbot.hardware_number }</option>
	}
	return null
}

function Toast(message) {
	toast.success(message)
}
function WarningToast(message) {
	toast.warning(message)
}

export default function AssignNBot(props) {
	const [nbot, setNbot] = useState(-1)

	const { data, loading, error } = useQuery(GET_NBOTS, {
		pollInterval: 1000 * 15,
	})
	const [put_nbot] = useMutation(PUT_NBOT, {
		onCompleted(r) {
			Toast("nBot Assigned to Classroom")
		}
	})

	// const [delete_nbot] = useMutation(DELETE_NBOT)

	const handleSubmit = async e => {
		e.preventDefault()
		if (nbot && nbot !== -1) {
			
			// await delete_nbot({
			// 	variables: {
			// 		nbot_id: nbot
			// 	}
			// })
			await put_nbot({ 
				variables: {
					classroom_id: props.id,
					nbot_id: nbot
				}
			})
		}
		else {
			WarningToast("Select nBot")
		}

	}


	if (loading) {
		return (
			<div>
				<h3>Loading</h3>
			</div>
		);
	}
	else if (error) {
		return (
			<Redirect to='/login' />
		)
	}    
	else {
		return (
			<div>
				<SectionHeader title='Add Camera' />
				<form onSubmit={ handleSubmit }>
					<div style={{ margin: '0 auto', display: 'block', width: '100%' }}>
						<select id='assign-nbot' value={ nbot } style={{ marginLeft: 0 }} onChange={ e => setNbot(Number(e.target.value)) } >
							<option value={ -1 }>{ "Unassigned nBots" }</option>
							{ data.nBots.map(nbot => (
								<UnassignedNbot nbot={ nbot } key={ nbot.id } />
							)) }
						</select>
						<button type="submit" value="Submit" className={ style.td_button + ' ' + style.submit_text }>Add Camera</button>
					</div>
				</form>
			</div>
		);
	}
}