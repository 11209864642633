import React from 'react';
import style from '../styling/LayoutCommon.module.css';

export default function Footer () {
	return (
		<div className={ style.footer }>
			<hr />
			<p>&copy; Note Taking Express 2020</p>
		</div>
	)
}