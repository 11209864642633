import React from 'react'
import { useParams, Redirect } from "react-router-dom"
import { useQuery } from '@apollo/react-hooks'

import gql from 'graphql-tag'
import PageHeader from '../../components/pageheader'
import style from '../../styling/Panels.module.css'

import UserInfo from './components/UserInfo'
import UpdatePassword from './components/UpdatePassword'

const GET_USER = gql`
  	query user($id: ID!) {
		user(id: $id) {
			id
			first_name
			last_name
			username
			email
			created_date
		}
	}
`;


export default function App() {
	let { id } = useParams()
	const { data, loading, error } = useQuery(
		GET_USER,
		{ variables: { id }}
	);

	if (loading) {
		return (
			<div>
				<PageHeader title="Loading" />
			</div>
		);
	}
	else if (error) {
		return (
			<Redirect to='/login' />
		)
	}
	else {
		let name = data.user.first_name + ' ' + data.user.last_name;
		return (
			<div>
				<PageHeader title={ name } />
				<div className={ style.panel_row }>
					<div className={ style.panel + ' ' + style.panel_single }>
						<UserInfo user={ data.user } />
					</div>
				</div>

				<div className={ style.panel_row }>
					<div className={ style.panel + ' ' + style.panel_double }>
						<UpdatePassword id={ id } />
					</div>
				</div>
			</div>
		);
	}
}