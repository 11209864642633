import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';
import {useQuery, useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import style from '../../../styling/Panels.module.css';
import Modal from 'react-modal';

import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

var moment = require('moment')
moment().format()

Modal.setAppElement('#root')

const DELETE_LECTURE = gql`
    mutation deleteBooking($id: ID) {
        deleteBooking(
            id: $id
        ) {
            id
        }
    }
`;

function Toast(message) {
	toast.success(message);
}

function ErrorToast(message) {
	toast.error(message);
}

const customStyles = {
	content : {
	    top                   : '30%',
	    left                  : '50%',
	    right                 : 'auto',
	    bottom                : 'auto',
	    marginRight           : '-50%',
	    transform             : 'translate(-50%, -50%)',
	    border				  : '1px solid #878787',
		padding				  : 0,
		boxShadow             : '0px 8px 15px rgba(181, 181, 181, 0.8)',
	}
}

function Component (props) {	
	const [deleteLecture] = useMutation(DELETE_LECTURE, {
		onError(e) {
			ErrorToast("Could not delete lecture")
		},
		onCompleted(r) {
			Toast("Lecture deleted")
			closeModal()
		}
	})
    	
	const [modalIsOpen, setIsOpen] = React.useState(false)
	function openModal() {
		setIsOpen(true)
	}
	function closeModal () {
		setIsOpen(false)
	}

	const handleSubmit = e => {
		e.preventDefault()

		deleteLecture({ 
			variables: {
				id: props.lecture_id
			}
		})
	}
    
	return (
		<>
			<div style={{ display:'block'}}>
				<button onClick={ openModal } className={ style.warning_text } style={{color: "RGB(218, 75, 98)"}}>Delete</button>
				<Modal
					isOpen={ modalIsOpen }
					onRequestClose={ closeModal }
					contentLabel="Delete Lecture"
					style={ customStyles }
				>
					<div className={ style.panel_row } style={{ marginBottom: 0 }}>
						<div className={ style.panel + ' ' + style.panel_modal }>
							<form onSubmit={ handleSubmit }>
								<div style={{ margin: 'auto'}}>
									<h3>Delete Lecture</h3>
									<div style={{ display: 'flex'}}>
										<button className={ style.form_button + ' ' + style.submit_text } onClick={closeModal}>Cancel</button>
										<button className={ style.form_button + ' ' + style.warning_text } type="submit">Delete</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</Modal>
			</div>
		</>
	)    
}

export default Component