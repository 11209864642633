import React, { useState } from 'react';
import {
	useParams, Redirect
} from "react-router-dom";
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PageHeader from '../../components/pageheader';
import ClassroomInfo from './components/ClassroomInfo';
import style from '../../styling/Panels.module.css';
import DeleteClassroom from './components/DeleteClassroom';
import AssignNBot from './components/AssignNbot';
import VideoModal from './components/VideoModal'
import StartCameras from './components/StartCameras';
import AssignCampus from './components/AssignCampus';
import SectionHeader from '../../components/sectionheader';

const GET_CLASSROOM = gql`
  	query classroom($id: ID!) {
		classroom(id: $id) {
			id
			name
			nBots {
				id
				hardware_number
			}
			campus{
				id
				name
			}
		}
	}
`;

export default function App() {
	let { id } = useParams();
	const { data, loading, error } = useQuery(
		GET_CLASSROOM,
		{ variables: { id: id }}
	);

	if (loading) {
		return (
			<div>
				<PageHeader title="Loading" />
			</div>
		);
	}
	else if (error) {
		return (
			<Redirect to='/login' />
		)
	}
	else {
		return (
			<div>
				<PageHeader title={ "Classroom - " + data.classroom.name } />

				<div className={ style.panel_row }>
					<div className={ style.panel + ' ' + style.panel_single }>
						<ClassroomInfo classroom={ data.classroom } />
					</div>
				</div>
				{
					!data.classroom.nbot ? 
						<div>
							<div className={ style.panel + ' ' + style.panel_single } style={{marginBottom: "20px"}}>
								{data.classroom.campus !== null ? 
								<StartCameras classroom={ data.classroom } nBots={ data.classroom.nBots} campus={ data.classroom.campus.name}/>: 
								<div>
									<SectionHeader title='Classroom Cameras Control' />
										<form>
											<p style={{border: "none", margin: "0 auto"}}>Assign Camera to Campus to see camera controls</p>
										</form>
								</div> }
							</div>
							<div className={ style.panel_row }>
								<div className={ style.panel + ' ' + style.panel_double }>
									<AssignCampus id={ data.classroom.id } campus_id={ data.classroom }/>
								</div>
								<div className={ style.panel + ' ' + style.panel_double }>
									<AssignNBot id={ data.classroom.id } />
								</div>
							</div>
						</div>:
						<div className={ style.panel + ' ' + style.panel_double }>
							<VideoModal url={ data.classroom.nbot.url } />
						</div>
				}
				<div>
					<DeleteClassroom classroom={ data.classroom } />
				</div>
			</div>
		)
	}
}