import React, { Fragment } from 'react';
import {
	Link, Redirect
} from "react-router-dom";
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import style from '../../styling/Panels.module.css';
import PageHeader from '../../components/pageheader';
import CreateCampus from './components/EditCreateCampus';

const GET_CAMPUS = gql`
  query campus_list {
      campuses {
          id
          name
      }
  }
`;

function Campus (props) {
	const campus = props.campus;
	return (
		<tr>
			<td>
				<Link to={`/campus/${campus.id}`}>{ campus.name }</Link>
			</td>
		</tr>
	)
}

function Campuslist () {
	const { data, loading, error } = useQuery(GET_CAMPUS, {
		pollInterval: 500,
	});
	if (loading)
		return (
			<tbody>
				<tr>
					<td>loading</td>
				</tr>
			</tbody>
		);
	else if (error) {
		return (
			<Redirect to='/login' />
		)
    }
    console.log("Data: ", data.campuses)
    if(data.campuses === null){
        return (
            <tbody>
                Campuses not available. Please add Campuses using add Campus button
            </tbody>
        )
    }else{
        return (
            <tbody>
                <Fragment>
                    {data.campuses.map(campus => (
                        <Campus key={ campus.id } campus={ campus } />
                    ))}
                </Fragment>
            </tbody>
        )
    }
	
}

function CampusModule() {
	return (
		<div className={ style.table_wrapper }>
			<table>
				<thead>
					<tr>
						<th>Campus</th>
					</tr>
				</thead>
				<Campuslist />
			</table>
		</div>
	)
}

export const App = () => {
	return (
		<div>
			<PageHeader title="Campus" />
			<div className={ style.panel_row }>	
				<div className={ style.panel + ' ' + style.panel_single } >
					<CampusModule />
				</div>
			</div>
			<CreateCampus campus={ null } usage={'Create'} />
		</div>
	);
};

export default App;