import React from 'react'
import style from '../../../styling/Panels.module.css'

var moment = require('moment')
moment().format()
moment.defaultFormat = "MMMM Do YYYY, h:mm:ss a"

function Component (props) {
	return (
		<div style={{ display: 'flex', alignItems: "center" }}>
			<div className={ style.table_wrapper } style={{ width: "100%" }}>
				<table>
					<thead>
						<tr>
							<th>Class</th>
							<th>Classroom</th>
							<th>Start Date</th>
							<th>End Date</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{props.lecture.class.name + ' (' + props.lecture.class.code + ')'}</td>
							<td>{props.lecture.classroom.name}</td>
							<td>{moment(props.lecture.start_time).format()}</td>
							<td>{moment(props.lecture.end_time).format()}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default Component