import React, { Fragment, useState } from 'react';
import {
	Link, Redirect
} from "react-router-dom";
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import style from '../../styling/Panels.module.css';
import PageHeader from '../../components/pageheader';
import CreateNbot from './components/EditNbot';
import RebootAllNbots from './components/EditNbot';

let filterUnassigned = "0";

const GET_NBOTS = gql`
  query nbot_list {
      nBots {
          id
		  hardware_number
		  online_status
		  classroom {
			  id
			  name
		  }
      }
  }
`;

const GET_STATUS = gql`
    query nBotStatus($nbot_id: ID!){
        nBotStatus(id: $nbot_id){
            online_status
        }
    }
`;

const customStyles = {
    online: {
        border: '1px solid #4AC948', 
        backgroundColor: '#4AC948',
        display: 'inline',
        paddingRight: '19px',
		borderRadius: '25px',
    },
    timeout: {
        border: '1px solid #ff3333', 
        backgroundColor: '#ff3333',
        display: 'inline',
        paddingRight: '19px',
		borderRadius: '25px',
    }
};

/**
 * @return {null}
 */
function NBot (props) {
	if (filterUnassigned === "1") {
		if (props.nBot.classroom) {
			return (
				<tr>
					<td>
						<Link to={`/nbots/${ props.nBot.id }`}>{ props.nBot.hardware_number }</Link>
					</td>
					<td>
						{ props.nBot.classroom.name }
					</td>
					<td>
						<NBotStatus id={ props.nBot.id }/>
					</td>
				</tr>
			)
		}
		return null;
	}
	else if (filterUnassigned === "2") {
		if (props.nBot.classroom === null) {
			return (
				<tr>
					<td>
						<Link to={`/nbots/${ props.nBot.id }`}>{ props.nBot.hardware_number }</Link>
					</td>
					<td>
						Unassigned
					</td>
					<td>
						<NBotStatus id={ props.nBot.id }/>
					</td>
				</tr>
			)
		}
		return null;
	} else {
		if ( props.nBot.classroom ) {
			return (
				<tr>
					<td>
						<Link to={`/nbots/${ props.nBot.id }`}>{ props.nBot.hardware_number }</Link>
					</td>
					<td>
						{ props.nBot.classroom.name }
					</td>
					<td>
						<NBotStatus id={ props.nBot.id }/>
					</td>
				</tr>
			)
		} else {
			return (
				<tr>
					<td>
						<Link to={`/nbots/${ props.nBot.id }`}>{ props.nBot.hardware_number }</Link>
					</td>
					<td>
						Unassigned
					</td>
					<td>
						<NBotStatus id={ props.nBot.id }/>
					</td>
				</tr>
			)
		}
	}
}

function NBotStatus (props) {
	const { data, loading, error } = useQuery(
        GET_STATUS, 
        { 
			variables: { nbot_id: props.id },
			pollInterval: 1000 * 10
		})
	if (loading) {
		return (
			<div>
				<p>Loading</p>
			</div>
		);
	}else if (error) {
		return (
			<Redirect to='/login' />
		)
	}else {
		return (
			<div>
				<a style={(data.nBotStatus.online_status === "true") ? customStyles.online: customStyles.timeout }></a>
                        <br />
                        {(data.nBotStatus.online_status === "true") ?
                            (
                                <a></a>
                            ) : 
                            (
                                <a></a>
                            )
                        }
			</div>
		);
	}
}

function NBotList () {
	let { data, loading, error } = useQuery(GET_NBOTS, {
		pollInterval: 1000 * 15,
	});

	const [q, setQ] = useState("")
	const [c, setC] = useState("")
	   
	function searchHardwareId(rows) {
		var nbots_array = []
		rows.nBots.map((row) => {
			if(row.hardware_number.indexOf(q) > -1){
					nbots_array.push(row)
				}
			}
		)
		return {nBots: nbots_array}
	}

	function searchClassroom(rows) {
		var classroom_array = []
		rows.nBots.map((row) => {
			if(row.classroom !== null){
				if(row.classroom.name.indexOf(c) > -1){
					classroom_array.push(row)
					}
				}
			}	
		)
		return {nBots: classroom_array}
	}

	function compare( a, b ) {
		if ( a.online_status < b.online_status ){
		  return 1;
		}
		if ( a.online_status > b.online_status ){
		  return -1;
		}
		return 0;
	}
	  

	if (loading) {
        return (
            <tbody>
                <tr>
                    <td>loading</td>
                    <td>loading</td>
                </tr>
            </tbody>
        );
	}
	else if (error) {
		return (
			<Redirect to='/login' />
		)
	}

	(data.nBots).sort( compare ) 
    return (
		<>
			<React.Fragment>
				<input type="text" value={q} onChange={e => setQ(e.target.value)} placeholder="Search by Launch Camera" style={{marginLeft: "0px", maxWidth: "180px"}} />
				<input type="text" value={c} onChange={e => setC(e.target.value)} placeholder="Search by classroom" style={{marginLeft: "10px", maxWidth: "180px"}}/>
			</React.Fragment>
			<tbody>
				<Fragment>
					{ (q) ? 
					( searchHardwareId(data).nBots.map(nbot => (
							<NBot key={ nbot.id } nBot={ nbot }/>			
					)) ) : (
						(c) ?
						(searchClassroom(data).nBots.map(nbot => (
								<NBot key={ nbot.id } nBot={ nbot }/>
						))) : (data.nBots.map(nbot => (
								<NBot key={ nbot.id } nBot={ nbot }/>
						)))
					) }
				</Fragment>
			</tbody>
		</>	
    )
}

export class FilterForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = { filterUnassigned };
	}

	// TODO: Refactor page to multiple components
	handleChange = event => {
		this.setState({filterUnassigned: event.target.value});
		filterUnassigned = event.target.value;
	}

	render() {
		return (
			<div>
				<PageHeader title="Launch Cameras" />
				<div className={ style.panel_row }>
					<div className={ style.panel + ' ' + style.panel_single } >
						<React.Fragment>
							<form className={ style.panel_radio }>
								<div className={ style.radio_group }>
									<input
										name="filter-unassigned" type="radio"
										value="0" checked={this.state.filterUnassigned === "0"} id="v0"
										onChange={this.handleChange}
									/>
									<label htmlFor="v0">
										All Launch Cameras
									</label>
									<input
										name="filter-unassigned" type="radio"
										value="1" checked={this.state.filterUnassigned === "1"}  id="v1"
										onChange={this.handleChange}
									/>
									<label htmlFor="v1">
										Assigned
									</label>
									
									<input
										name="filter-unassigned" type="radio"
										value="2" checked={this.state.filterUnassigned === "2"}  id="v2"
										onChange={this.handleChange}
									/>
									<label htmlFor="v2">
										Unassigned
									</label>
								</div>
							</form>
							<div className={ style.table_wrapper }>
								<table>
									<thead>
										<tr>
											<th>
												Launch Camera hardware id
											</th>
											<th>
												classroom
											</th>
											<th>
												Status
											</th>
										</tr>
									</thead>
									<NBotList />
								</table>
							</div>
						</React.Fragment>
					</div>
				</div>
				<CreateNbot nbot={ null } usage={'Create'} />
				<RebootAllNbots nbot={ null } usage={'RebootAll'} />
			</div>
		);
	}
}

export default FilterForm;