import React from 'react';
import style from '../../../styling/Panels.module.css';
import EditClass from './EditClass'
var moment = require('moment')
moment().format()
moment.defaultFormat = "MMMM Do YYYY, h:mm:ss a"

function Component (props) {
	return (
		<div style={{ display: 'flex', alignItems: "center" }}>
			<div className={ style.table_wrapper } style={{ width: "100%" }}>
				<table>
					<thead>
						<tr>
							<th>Name</th>
							<th>Code</th>
							<th>Start Date</th>
							<th>End Date</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{props.class.name}</td>
							<td>{props.class.code}</td>
							<td>{moment.unix(props.class.start_date / 1000).format()}</td>
							<td>{moment.unix(props.class.end_date / 1000).format()}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<EditClass class={props.class} usage={'Edit'} />
		</div>
	);
}

export default Component