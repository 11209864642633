import React, { useState, useReducer, useEffect } from 'react';
import Modal from 'react-modal';
import SectionHeader from '../../../components/sectionheader';
import { toast } from 'react-toastify';
import useSpinner from '../../nbot/components/spinner/useSpinner'
import style from '../../../styling/Panels.module.css';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

Modal.setAppElement('#root')

const JOIN_ROOM = gql`
	mutation joinRoom($type: String!, $id: ID!, $roomID: String!, $classAssignment: String!) {
		joinRoom(
			type: $type,
			id: $id,
			roomID: $roomID,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_LEAVE_ROOM = gql`
	mutation handleLeaveRoom($type: String!, $id: ID!, $classAssignment: String!) {
		handleLeaveRoom(
			type: $type,
			id: $id,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_LEAVE_ROOM_EFFECT = gql`
	mutation handleLeaveRoomEffect($type: String!, $id: ID!, $classAssignment: String!) {
		handleLeaveRoomEffect(
			type: $type,
			id: $id,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_REBOOT = gql`
	mutation handleReboot($type: String!, $id: ID!, $classAssignment: String!) {
		handleReboot(
			type: $type,
			id: $id,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_PLAY_AUDIO = gql`
	mutation handlePlayAudio($type: String!, $id: ID!, $classAssignment: String!) {
		handlePlayAudio(
			type: $type,
			id: $id,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_STOP_AUDIO = gql`
	mutation handleStopAudio($type: String!, $id: ID!, $classAssignment: String!) {
		handleStopAudio(
			type: $type,
			id: $id,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_RESOLUTION = gql`
	mutation handleResolution($type: String!, $id: ID!, $resolution: String!, $classAssignment: String!) {
		handleResolution(
			type: $type,
			id: $id,
			resolution: $resolution,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_MUTE_AUDIO = gql`
	mutation handleMuteAudio($type: String!, $id: ID!, $classAssignment: String!) {
		handleMuteAudio(
			type: $type,
			id: $id,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_UNMUTE_AUDIO = gql`
	mutation handleUnMuteAudio($type: String!, $id: ID!, $classAssignment: String!) {
		handleUnMuteAudio(
			type: $type,
			id: $id,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_HIDE_VIDEO = gql`
	mutation handleHideVideo($type: String!, $id: ID!, $classAssignment: String!) {
		handleHideVideo(
			type: $type,
			id: $id,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_SHOW_VIDEO = gql`
	mutation handleShowVideo($type: String!, $id: ID!, $classAssignment: String!) {
		handleShowVideo(
			type: $type,
			id: $id,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_SHUTDOWN = gql`
	mutation handleShutdown($type: String!, $id: ID!, $classAssignment: String!) {
		handleShutdown(
			type: $type,
			id: $id,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const customStyles = {
	content : {
	    top                   : '45%',
	    left                  : '50%',
	    right                 : 'auto',
	    bottom                : 'auto',
	    marginRight           : '-50%',
	    transform             : 'translate(-50%, -50%)',
	    border  			  : '1px solid #878787',
		padding				  : 0,
		width                 : '70%',
		boxShadow             : '0px 8px 15px rgba(181, 181, 181, 0.8)',
    },
    button: {
		marginLeft: '10px', 
		padding: '3.5px 3.5px', 
		width: 'auto', 
		border: 'solid 1px black', 
		borderRadius: '4px',
		backgroundColor: '#E0E0E0'
    },
    button_right:{
        margin: '0 10px', 
		padding: '3.5px 3.5px', 
		width: 'auto', 
		border: 'solid 1px black', 
		borderRadius: '4px',
		backgroundColor: '#E0E0E0'
    },
    button_leaveRoom: {
		marginLeft: '10px', 
		padding: '3.5px 3.5px', 
		width: 'auto', 
		border: 'solid 1px black', 
		borderRadius: '4px',
		backgroundColor: '#E0E0E0'
    },
};

function Toast(message) {
	toast.success(message);
}

function ToastWarning(message) {
	toast.warn(message);
}

function NBotsList({ joinedRoom, spinner, 
                     resolutions, classroomName, campus, 
                     id, hardware_id, forceUpdate, resolution,
                     showSpinner, hideSpinner, classroom }) {
    
    const [handle_reboot] = useMutation(HANDLE_REBOOT, {
        onCompleted: (r) =>{
            hideSpinner()
            Toast("Successfully rebooting Launch Camera")
        }
    })

    const [handle_play_audio] = useMutation(HANDLE_PLAY_AUDIO, {
		onCompleted: (r) =>{
			hideSpinner()
			Toast("Play Audio enabled on Launch Camera")
		}
    })
    
    const [handle_stop_audio] = useMutation(HANDLE_STOP_AUDIO, {
		onCompleted: (r) =>{
			hideSpinner()
			Toast("Stop Audio enabled on Launch Camera")
		}
    })

    const [handle_mute_audio] = useMutation(HANDLE_MUTE_AUDIO, {
		onCompleted: (r) =>{
			hideSpinner()
			Toast("Mute Audio enabled on Launch Camera")
		}
	})

	const [handle_unmute_audio] = useMutation(HANDLE_UNMUTE_AUDIO, {
		onCompleted: (r) =>{
			hideSpinner()
			Toast("Unmute Audio enabled on Launch Camera")
		}
    })
    
    const [handle_hide_video] = useMutation(HANDLE_HIDE_VIDEO, {
		onCompleted: (r) =>{
			hideSpinner()
			Toast("Hide video enabled on Launch Camera")
		}
	})

	const [handle_show_video] = useMutation(HANDLE_SHOW_VIDEO, {
		onCompleted: (r) =>{
			hideSpinner()
			Toast("Show video enabled on Launch Camera")
		}
	})
    
    const [handle_shutdown] = useMutation(HANDLE_SHUTDOWN, {
		onCompleted: (r) =>{
			hideSpinner()
			Toast("Successfully shutting down Launch Camera")
		}
	})
    
    const [handle_resolution] = useMutation(HANDLE_RESOLUTION, {
		onCompleted: (r) =>{
			setTimeout(() => hideSpinner(), 4500)
			if (window["resolution" + id] == "Low ~ 320x240"){
            	Toast("Setting resolution to Low ~ 320x240")
            }
            if (window["resolution" + id] == "Medium ~ 640x480"){
            	Toast("Setting resolution to Medium ~ 640x480")
            }
            if (window["resolution" + id] == "High ~ 1280x720"){
            	Toast("Setting resolution to High ~ 1280x720")
            }
            if (window["resolution" + id] == "Very High ~ 1920x1080"){
            	Toast("Setting resolution to Very High ~ 1920x1080")
            }
            if (window["resolution" + id] == "Ultra HD ~ 3840x2560"){
            	Toast("Setting resolution to Ultra HD ~ 3840x2560")
            }
		}
	})

    const handleReboot = e => {
		e.preventDefault();

        var classAssignment = JSON.stringify(classroom)
        showSpinner()
        handle_reboot({
            variables: {
                "type": "reboot",
                "id": id,
                "classAssignment": classAssignment
            }
        })
	}

	const handlePlayAudio = e =>{
		e.preventDefault();

		var classAssignment = JSON.stringify(classroom)
        showSpinner()
        handle_play_audio({
            variables: {
                "type": "audio_test",
                "id": id,
                "classAssignment": classAssignment
            }
        })
	}

	const handleStopAudio = e => {
		e.preventDefault();

		var classAssignment = JSON.stringify(classroom)
        showSpinner()
        handle_stop_audio({
            variables: {
                "type": "audio_test",
                "id": id,
                "classAssignment": classAssignment
            }
        })
	}

	const handleResolution = e => {
		e.preventDefault();
        
        var classAssignment = JSON.stringify(classroom)
        showSpinner()
		handle_resolution({
			variables: {
				"type": "resolution",
				"id": id,
				"resolution": window["resolution" + id],
				"classAssignment": classAssignment
			}
		})
	}

	const handleMuteAudio = e => {
        window["audio" + id] = true
		e.preventDefault();

		var classAssignment = JSON.stringify(classroom)
        showSpinner()
		handle_mute_audio({
            variables: {
                "type": "audio_stream",
                "id": id,
                "classAssignment": classAssignment
            }
        })
        
        forceUpdate();
		
	}

	const handleUnMuteAudio = e => {
        window["audio" + id] = false
		e.preventDefault();

		var classAssignment = JSON.stringify(classroom)
        showSpinner()
		handle_unmute_audio({
            variables: {
                "type": "audio_stream",
                "id": id,
                "classAssignment": classAssignment
            }
        })
        
        forceUpdate();
		
	}

	const handleHideVideo = e => {
        window["video" + id] = true
		e.preventDefault();

		var classAssignment = JSON.stringify(classroom)
        showSpinner()
		handle_hide_video({
            variables: {
                "type": "video_stream",
                "id": id,
                "classAssignment": classAssignment
            }
        })
        
        forceUpdate();
		
	}

	const handleShowVideo = e => {
        window["video" + id] = false
		e.preventDefault();

		var classAssignment = JSON.stringify(classroom)
        showSpinner()
		handle_show_video({
            variables: {
                "type": "video_stream",
                "id": id,
                "classAssignment": classAssignment
            }
        })
        
        forceUpdate();
		
	}

	const handleShutDown = e => {
		e.preventDefault();

		var classAssignment = JSON.stringify(classroom)
        showSpinner()
		handle_shutdown({
            variables: {
                "type": "shutdown",
                "id": id,
                "classAssignment": classAssignment
            }
        })
	}

    return (
        joinedRoom ?
            (<div className= {style.control_buttons} key={id}>
                <label style={{marginLeft: "10px", minWidth: "80px"}}>{hardware_id}</label>
                <button type="submit" value="Submit" onClick={handlePlayAudio} style={ customStyles.button }>Play Audio</button>
                <button type="submit" value="Submit" onClick={handleStopAudio} style={ customStyles.button }>Stop Audio</button>
                <form onSubmit={ handleResolution }>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <select id='assign-resolution' value={window["resolution" + id]} style={{ marginLeft: 0 }} onChange={ e => {window["resolution" + id]=(e.target.value);} }>
                            <option value={ -1 }>{ "Resolution" }</option>
                            {resolutions.map(resolution => (
                                <option value={ resolution } key={resolution}>{ resolution }</option>
                        ))}
                        </select>
                        <button type="submit" value="Submit" className={ style.td_button + ' ' + style.submit_text }>Set Resolution</button>
                    </div>
                </form>     
                { !window["audio" + id] ? 
                    (<button type="submit" value="Submit" onClick={handleMuteAudio} style={ customStyles.button }>Mute Audio</button>) : 
                    (<button type="submit" value="Submit" onClick={handleUnMuteAudio} style={ customStyles.button }>Unmute Audio</button>) 
                }
                { !window["video" + id] ? 
                    (<button type="submit" value="Submit" onClick={handleHideVideo} style={ customStyles.button }>Hide Video</button>) : 
                    (<button type="submit" value="Submit" onClick={handleShowVideo} style={ customStyles.button }>Show Video</button>) 
                }
                {/* <button type="submit" value="Submit" onClick={handleReboot} style={ customStyles.button_right }>Reboot</button> */}
                {/* <button type="submit" value="Submit" onClick={handleShutDown} style={ customStyles.button_right }>Shutdown</button> */}
            </div>) : ''
    )
}

export default function StartCameras ({ classroom, nBots, campus }) {

    const resolutions = ['Low ~ 320x240', 'Medium ~ 640x480', 'High ~ 1280x720', 'Very High ~ 1920x1080', 'Ultra HD ~ 3840x2560']
	const [ modalIsOpen, setIsOpen ] = React.useState(false);

	const [ roomID, set_roomID] = useState('test_room')

	const [ joinedRoom, set_joinedRoom ] = useState(false)
	const [ resolution, setResolution] = useState('High');
	const [ audio, setAudio ] = useState(false)
	const [ video, setVideo ] = useState(false)

    const [spinner, showSpinner, hideSpinner] = useSpinner();

    
    
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    
	function openModal() {
		setIsOpen(true);
	}
  
	function closeModal () {
		setIsOpen(false);
    }
    
    const [ join_room ] = useMutation(JOIN_ROOM, {
		onCompleted: (r) =>{
			setTimeout(() => {set_joinedRoom(true);hideSpinner()}, 3500)
			Toast("Launch Cameras Joined to Classroom")
		}
    })

    const [handle_leave_room] = useMutation(HANDLE_LEAVE_ROOM, {
		onCompleted: (r) =>{
			hideSpinner()
			Toast("Successfully Left Room")
		}
    })
    
    

	const [handle_leave_room_effect] = useMutation(HANDLE_LEAVE_ROOM_EFFECT)

    const openInNewTab = (url) => {
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
		if (newWindow) newWindow.opener = null
	}

    const sendPublish = e => {
        e.preventDefault();
        
        if (nBots.length > 0){
            for (let i = 0; i < nBots.length; i++){
                var classAssignment = JSON.stringify(classroom)
                showSpinner()
                join_room({
                    variables: {
                        "type": "stream",
                        "id": nBots[i].id,
                        "roomID": classroom.name.replace(/ /g,"_"),
                        "classAssignment": classAssignment
                    }
                })
			}
			
			openInNewTab(openInNewTab(`https://room.launch.camera/${classroom.name.replace(/ /g,"_")}`))
        }else{
            ToastWarning("No Cameras assigned to classroom!")
        }   
    }

    const handleLeaveRoom = e => {
		e.preventDefault();

		for (let i = 0; i < nBots.length; i++){
            var classAssignment = JSON.stringify(classroom)
            showSpinner()
            handle_leave_room({
                variables: {
                    "type": "stream_off",
                    "id": nBots[i].id,
                    "classAssignment": classAssignment
                }
            })
        }
        
		set_joinedRoom(false)
        
    }

    useEffect(() => {
		return () => {
			for (let i = 0; i < nBots.length; i++){
                var classAssignment = JSON.stringify(classroom)
                handle_leave_room_effect({
                    variables: {
                        "type": "stream_off",
                        "id": nBots[i].id,
                        "classAssignment": classAssignment
                    }
                })
            }
		}
	}, [])

	return (
		<>
			<SectionHeader title='Classroom Cameras Control' />
                <div style={{ paddingBottom: "10px"}}>
                    { (!joinedRoom && !spinner) ?
                        (<button type="submit" value="Submit" onClick={sendPublish} style={ customStyles.button }>Join Classroom</button>
                        ) : ''
                    }
                    {nBots.map(nBot => (
                        <NBotsList  key={nBot.id}
                                    joinedRoom={joinedRoom} 
                                    spinner={spinner} 
                                    resolutions={resolutions} 
                                    resolution={resolution}
                                    classroomName={classroom.name}
                                    campus={campus}
                                    id={nBot.id}
                                    showSpinner={showSpinner}
                                    hideSpinner={hideSpinner}
                                    hardware_id={nBot.hardware_number}
                                    forceUpdate={forceUpdate}
                                    classroom={classroom}
                        />
                    ))}
                    {joinedRoom && !spinner ? (<div><br /><button type="submit" value="Submit" onClick={handleLeaveRoom} style={ customStyles.button_leaveRoom }>Leave Classroom</button></div>) : '' }
                </div>
                <div style={{maxWidth: "100px", maxHeight: "100px", paddingLeft: "450px"}}>
                    { spinner }
                </div>
		</>
	);
}