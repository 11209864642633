import React from 'react'
import Spinnericon from './Spinnericon'

const Spinner = props => {
    return (
        <div className="Spinner">
            <Spinnericon />
        </div>
    )
}

export default Spinner
