import React from 'react'
import { Link, Redirect } from "react-router-dom"
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import style from '../../styling/Panels.module.css'
import PageHeader from '../../components/pageheader'

var moment = require('moment')
moment().format("MMM Do YYYY, h:mm a")

const GET_CLASSES = gql`
  	query lecture_list {
		bookings {
			id
			class {
				id
				name
				code
			}
			classroom {
				id
				name
			}
			start_time
			end_time
		}
	}`

/**
 * @return {null}
 */
function Lecture (props) {
	return (
		<tr>
			<td><Link to={`/lectures/${ props.lecture.id }`}>{props.lecture.id}</Link></td>
			<td><Link to={`/classes/${ props.lecture.class.id }`}>{props.lecture.class.name}</Link></td>
			<td><Link to={`/classes/${ props.lecture.class.id }`}>{props.lecture.class.code}</Link></td>
			<td>{moment.unix(props.lecture.start_time / 1000).format("MMM Do YYYY, h:mm a")}</td>
			<td>{moment.unix(props.lecture.end_time / 1000).format("MMM Do YYYY, h:mm a")}</td>
		</tr>
	)
}

function Component() {
	const { data, loading, error } = useQuery(GET_CLASSES, {
		pollInterval: 1000 * 10,
	})

	if (loading) {
		return (
			<h1>Loading</h1>
		)
	}
	else if (error) {
		console.log(error)
		return (
			<Redirect to='/login' />
		)
	}
	else {
		return (
			<div>
				<PageHeader title="Lectures" />
				<div className={ style.panel_row }>	
					<div className={ style.panel + ' ' + style.panel_single } >
						<div className={ style.table_wrapper }>
							<table>
								<thead>
									<tr>
										<th>Lecture ID</th>
										<th>Class</th>
										<th>Class Code</th>
										<th>Start Time</th>
										<th>End Time</th>
									</tr>
								</thead>
								<tbody>
									{
										data.bookings 
											? data.bookings.map(lecture => (
												<Lecture key={ lecture.id } lecture={ lecture } />
											))
											: null
									}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Component;