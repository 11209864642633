import React, { Fragment } from 'react';
import {
	Link, Redirect
} from "react-router-dom";
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import style from '../../styling/Panels.module.css';
import PageHeader from '../../components/pageheader';
import CreateClassroom from './components/EditCreateClassroom';

const GET_CLASSROOMS = gql`
  query classroom_list {
      classrooms {
          id
          name
      }
  }
`;

function Classroom (props) {
	const classroom = props.classroom;
	return (
		<tr>
			<td>
				<Link to={`/classrooms/${classroom.id}`}>{ classroom.name }</Link>
			</td>
		</tr>
	)
}

function ClassroomList () {
	const { data, loading, error } = useQuery(GET_CLASSROOMS, {
		pollInterval: 500,
	});
	if (loading)
		return (
			<tbody>
				<tr>
					<td>loading</td>
				</tr>
			</tbody>
		);
	else if (error) {
		return (
			<Redirect to='/login' />
		)
	}
	return (
		<tbody>
			<Fragment>
				{data.classrooms.map(classroom => (
					<Classroom key={ classroom.id } classroom={ classroom } />
				))}
			</Fragment>
		</tbody>
	)
}

function ClassroomModule() {
	return (
		<div className={ style.table_wrapper }>
			<table>
				<thead>
					<tr>
						<th>Classrooms</th>
					</tr>
				</thead>
				<ClassroomList />
			</table>
		</div>
	)
}

export const App = () => {
	return (
		<div>
			<PageHeader title="Classrooms" />
			<div className={ style.panel_row }>	
				<div className={ style.panel + ' ' + style.panel_single } >
					<ClassroomModule />
				</div>
			</div>
			<CreateClassroom classroom={ null } usage={'Create'} />
		</div>
	);
};

export default App;