import React, { useState } from 'react';
import {
	useParams, Redirect
} from "react-router-dom";
import { useQuery, useSubscription } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PageHeader from '../../components/pageheader';
import style from '../../styling/Panels.module.css';

import ClassInfo from './components/ClassInfo'
import ClassLectures from './components/ClassLectures'
import DeleteClass from './components/DeleteClass'
import ScheduleLectures from './components/ScheduleLectures'
import Cookies from 'universal-cookie'
const GET_CLASS = gql`
query class($class_id: ID!) {
	class(id: $class_id) {
		id
		name
		code
		start_date
		end_date
		bookings {
			id
			classroom {
				id
				name
				nBot {
					id
					url
				}
			}
			start_time
			end_time
			stream_permission
		}
	}
}`


function Component(props) {
	const cookies = new Cookies()
	let {id} = useParams()
	const {data, loading, error} = useQuery(
		GET_CLASS,
		{
			variables: {class_id: id},
			pollInterval: 1000 * 2
		}
	)

	if (loading) {
		return (
			<div>
				<PageHeader title="Loading" />
			</div>
		)
	}
	else if (error) {
		console.log(error)
		return (
			<div>
				<PageHeader title="Error" />
			</div>
		)
	}
	else {
		return (
			<div>
				<PageHeader title={data.class.name + ' (' + data.class.code + ')'} />

				<div className={ style.panel_row }>
					<div className={ style.panel + ' ' + style.panel_single }>
						<ClassInfo class={ data.class } />
					</div>
				</div>

				<div className={ style.panel_row }>
					<div className={ style.panel + ' ' + style.panel_single }>
						<ClassLectures lectures={data.class.bookings} class_id={data.class.id} />
					</div>
				</div>

				<div>
					{
						cookies.get('x-role') === 'NOTETAKER'
							? null
							: <DeleteClass class={data.class} />
					}
					<ScheduleLectures class={data.class} />
				</div>
			</div>
		)
	}
}

export default Component