import React, { useState, useEffect } from 'react';
import style from '../../../styling/Panels.module.css';
import SectionHeader from '../../../components/sectionheader';
import "react-toastify/dist/ReactToastify.css";
import 'react-circular-progressbar/dist/styles.css'
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import useSpinner from './spinner/useSpinner'
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const JOIN_ROOM = gql`
	mutation joinRoom($type: String!, $id: ID!, $roomID: String!, $classAssignment: String!) {
		joinRoom(
			type: $type,
			id: $id,
			roomID: $roomID,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_REBOOT = gql`
	mutation handleReboot($type: String!, $id: ID!, $classAssignment: String!) {
		handleReboot(
			type: $type,
			id: $id,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_PLAY_AUDIO = gql`
	mutation handlePlayAudio($type: String!, $id: ID!, $classAssignment: String!) {
		handlePlayAudio(
			type: $type,
			id: $id,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_STOP_AUDIO = gql`
	mutation handleStopAudio($type: String!, $id: ID!, $classAssignment: String!) {
		handleStopAudio(
			type: $type,
			id: $id,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_RESOLUTION = gql`
	mutation handleResolution($type: String!, $id: ID!, $resolution: String!, $classAssignment: String!) {
		handleResolution(
			type: $type,
			id: $id,
			resolution: $resolution,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_MUTE_AUDIO = gql`
	mutation handleMuteAudio($type: String!, $id: ID!, $classAssignment: String!) {
		handleMuteAudio(
			type: $type,
			id: $id,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_UNMUTE_AUDIO = gql`
	mutation handleUnMuteAudio($type: String!, $id: ID!, $classAssignment: String!) {
		handleUnMuteAudio(
			type: $type,
			id: $id,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_HIDE_VIDEO = gql`
	mutation handleHideVideo($type: String!, $id: ID!, $classAssignment: String!) {
		handleHideVideo(
			type: $type,
			id: $id,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_SHOW_VIDEO = gql`
	mutation handleShowVideo($type: String!, $id: ID!, $classAssignment: String!) {
		handleShowVideo(
			type: $type,
			id: $id,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_SHUTDOWN = gql`
	mutation handleShutdown($type: String!, $id: ID!, $classAssignment: String!) {
		handleShutdown(
			type: $type,
			id: $id,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_LEAVE_ROOM = gql`
	mutation handleLeaveRoom($type: String!, $id: ID!, $classAssignment: String!) {
		handleLeaveRoom(
			type: $type,
			id: $id,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_LEAVE_ROOM_EFFECT = gql`
	mutation handleLeaveRoomEffect($type: String!, $id: ID!, $classAssignment: String!) {
		handleLeaveRoomEffect(
			type: $type,
			id: $id,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const customStyles = {
	content : {
	    top                   : '30%',
	    left                  : '50%',
	    right                 : 'auto',
	    bottom                : 'auto',
	    marginRight           : '-50%',
	    transform             : 'translate(-50%, -50%)',
	    border				  : '1px solid #878787',
		padding				  : 0,
		boxShadow             : '0px 8px 15px rgba(181, 181, 181, 0.8)',
	},
	button: {
		marginLeft: '10px', 
		padding: '3.5px 3.5px', 
		width: 'auto', 
		border: 'solid 1px black', 
		borderRadius: '4px',
		backgroundColor: '#E0E0E0'
	}
};

function Toast(message) {
	toast.success(message);
}

function ToastWarning(message) {
	toast.warn(message);
}

export default function ControlsNbot ({id, classAssignment}) {

	const resolutions = ['Low ~ 320x240', 'Medium ~ 640x480', 'High ~ 1280x720', 'Very High ~ 1920x1080', 'Ultra HD ~ 3840x2560']
	const [ modalIsOpen, setIsOpen ] = React.useState(false);

	const [ roomID, set_roomID] = useState('test_room')

	const [ joinedRoom, set_joinedRoom ] = useState(false)
	const [ resolution, setResolution] = useState('High');
	const [ audio, setAudio ] = useState(false)
	const [ video, setVideo ] = useState(false)

	const [join_room] = useMutation(JOIN_ROOM, {
		onCompleted: (r) =>{
			setTimeout(() => {set_joinedRoom(true);hideSpinner()}, 6000)
			Toast("Launch Camera Joined to Classroom")
		}
	})

	const [handle_reboot] = useMutation(HANDLE_REBOOT, {
		onCompleted: (r) =>{
			hideSpinner()
			Toast("Successfully rebooting Launch Camera")
		}
	})

	const [handle_play_audio] = useMutation(HANDLE_PLAY_AUDIO, {
		onCompleted: (r) =>{
			hideSpinner()
			Toast("Play Audio enabled on Launch Camera")
		}
	})

	const [handle_stop_audio] = useMutation(HANDLE_STOP_AUDIO, {
		onCompleted: (r) =>{
			hideSpinner()
			Toast("Stop Audio enabled on Launch Camera")
		}
	})

	const [handle_resolution] = useMutation(HANDLE_RESOLUTION, {
		onCompleted: (r) =>{
			setTimeout(() => hideSpinner(), 4500)
			if (resolution == "Low ~ 320x240"){
            	Toast("Setting resolution to Low ~ 320x240")
            }
            if (resolution == "Medium ~ 640x480"){
            	Toast("Setting resolution to Medium ~ 640x480")
            }
            if (resolution == "High ~ 1280x720"){
            	Toast("Setting resolution to High ~ 1280x720")
            }
            if (resolution == "Very High ~ 1920x1080"){
            	Toast("Setting resolution to Very High ~ 1920x1080")
            }
            if (resolution == "Ultra HD ~ 3840x2560"){
            	Toast("Setting resolution to Ultra HD ~ 3840x2560")
            }
		}
	})

	const [handle_mute_audio] = useMutation(HANDLE_MUTE_AUDIO, {
		onCompleted: (r) =>{
			hideSpinner()
			Toast("Mute Audio enabled on Launch Camera")
		}
	})

	const [handle_unmute_audio] = useMutation(HANDLE_UNMUTE_AUDIO, {
		onCompleted: (r) =>{
			hideSpinner()
			Toast("Unmute Audio enabled on Launch Camera")
		}
	})

	const [handle_hide_video] = useMutation(HANDLE_HIDE_VIDEO, {
		onCompleted: (r) =>{
			hideSpinner()
			Toast("Hide video enabled on Launch Camera")
		}
	})

	const [handle_show_video] = useMutation(HANDLE_SHOW_VIDEO, {
		onCompleted: (r) =>{
			hideSpinner()
			Toast("Show video enabled on Launch Camera")
		}
	})

	const [handle_shutdown] = useMutation(HANDLE_SHUTDOWN, {
		onCompleted: (r) =>{
			hideSpinner()
			Toast("Successfully shutting down Launch Camera")
		}
	})

	const [handle_leave_room] = useMutation(HANDLE_LEAVE_ROOM, {
		onCompleted: (r) =>{
			hideSpinner()
			Toast("Successfully Left Room")
		}
	})

	const [handle_leave_room_effect] = useMutation(HANDLE_LEAVE_ROOM_EFFECT)

	const [spinner, showSpinner, hideSpinner] = useSpinner();
    
	function openModal() {
		setIsOpen(true);
	}
  
	function closeModal () {
		setIsOpen(false);
	}

	const openInNewTab = (url) => {
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
		if (newWindow) newWindow.opener = null
	}

	const handleSubmit = async e => {
		if (roomID === ''){
			alert('Room ID cannot be empty', {autoClose: 8000})
			return false
		}
		e.preventDefault();

		if (classAssignment === null){
			classAssignment = 'null'
			showSpinner()
			join_room({
				variables: {
					"type": "stream",
					"id": id,
					"roomID": roomID,
					"classAssignment": classAssignment
				}
			})
			openInNewTab(`https://room.launch.camera/${roomID}`)		
		}else{
			console.log(classAssignment)
			let classAssignmentName = classAssignment.name
			let classAssignmentCampus = classAssignment.campus
			classAssignment = JSON.stringify(classAssignment)

			if(classAssignmentCampus !== null){
				showSpinner()
				join_room({
					variables: {
						"type": "stream",
						"id": id,
						"roomID": roomID,
						"classAssignment": classAssignment
					}
				})
				openInNewTab(`https://room.launch.camera/${roomID}`)
			}else{
				ToastWarning("Classroom " + classAssignmentName + " is not assigned to a campus")
			}
			
		}
		closeModal()
	}

	const handleReboot = e => {
		e.preventDefault();

		if (classAssignment === null){
			classAssignment = 'null'
			showSpinner()
			handle_reboot({
				variables: {
					"type": "reboot",
					"id": id,
					"classAssignment": classAssignment
				}
			})		
		}else{
			let classAssignmentName = classAssignment.name
			let classAssignmentCampus = classAssignment.campus
			classAssignment = JSON.stringify(classAssignment)
			if(classAssignmentCampus !== null){
				showSpinner()
				handle_reboot({
					variables: {
						"type": "reboot",
						"id": id,
						"classAssignment": classAssignment
					}
				})
			}else{
				ToastWarning("Classroom " + classAssignmentName + " is not assigned to a campus")
			}
			
		}

		set_joinedRoom(false)
	}

	const handlePlayAudio = e =>{
		e.preventDefault();

		if (classAssignment === null){
			classAssignment = 'null'
			showSpinner()
			handle_play_audio({
				variables: {
					"type": "audio_test",
					"id": id,
					"classAssignment": classAssignment
				}
			})		
		}else{
			let classAssignmentName = classAssignment.name
			let classAssignmentCampus = classAssignment.campus
			classAssignment = JSON.stringify(classAssignment)

			if(classAssignmentCampus !== null){
				showSpinner()
				handle_play_audio({
					variables: {
						"type": "audio_test",
						"id": id,
						"classAssignment": classAssignment
					}
				})
			}else{
				ToastWarning("Classroom " + classAssignmentName + " is not assigned to a campus")
			}
			
		}
	}

	const handleStopAudio = e => {
		e.preventDefault();

		if (classAssignment === null){
			showSpinner()
			classAssignment = 'null'
			handle_stop_audio({
				variables: {
					"type": "audio_test",
					"id": id,
					"classAssignment": classAssignment
				}
			})		
		}else{
			let classAssignmentName = classAssignment.name
			let classAssignmentCampus = classAssignment.campus
			classAssignment = JSON.stringify(classAssignment)

			if(classAssignmentCampus !== null){
				showSpinner()
				handle_stop_audio({
					variables: {
						"type": "audio_test",
						"id": id,
						"classAssignment": classAssignment
					}
				})
			}else{
				ToastWarning("Classroom " + classAssignmentName + " is not assigned to a campus")
			}
			
		}
	}

	const handleResolution = e => {
		e.preventDefault();

		if (classAssignment === null){
			showSpinner()
			classAssignment = 'null'
			handle_resolution({
				variables: {
					"type": "resolution",
					"id": id,
					"resolution": resolution,
					"classAssignment": classAssignment
				}
			})		
		}else{
			let classAssignmentName = classAssignment.name
			let classAssignmentCampus = classAssignment.campus
			classAssignment = JSON.stringify(classAssignment)

			if(classAssignmentCampus !== null){
				showSpinner()
				handle_resolution({
					variables: {
						"type": "resolution",
						"id": id,
						"resolution": resolution,
						"classAssignment": classAssignment
					}
				})
			}else{
				ToastWarning("Classroom " + classAssignmentName + " is not assigned to a campus")
			}
		}
	}

	const handleMuteAudio = e => {
		e.preventDefault();

		if (classAssignment === null){
			showSpinner()
			classAssignment = 'null'
			handle_mute_audio({
				variables: {
					"type": "audio_stream",
					"id": id,
					"classAssignment": classAssignment
				}
			})		
		}else{
			let classAssignmentName = classAssignment.name
			let classAssignmentCampus = classAssignment.campus
			classAssignment = JSON.stringify(classAssignment)

			if(classAssignmentCampus !== null){
				showSpinner()
				handle_mute_audio({
					variables: {
						"type": "audio_stream",
						"id": id,
						"classAssignment": classAssignment
					}
				})
			}else{
				ToastWarning("Classroom " + classAssignmentName + " is not assigned to a campus")
			}
		}

		setAudio(true)
	}

	const handleUnMuteAudio = e => {
		e.preventDefault();

		if (classAssignment === null){
			showSpinner()
			classAssignment = 'null'
			handle_unmute_audio({
				variables: {
					"type": "audio_stream",
					"id": id,
					"classAssignment": classAssignment
				}
			})		
		}else{
			let classAssignmentName = classAssignment.name
			let classAssignmentCampus = classAssignment.campus
			classAssignment = JSON.stringify(classAssignment)

			if(classAssignmentCampus !== null){
				showSpinner()
				handle_unmute_audio({
					variables: {
						"type": "audio_stream",
						"id": id,
						"classAssignment": classAssignment
					}
				})
			}else{
				ToastWarning("Classroom " + classAssignmentName + " is not assigned to a campus")
			}
		}

		setAudio(false)
	}

	const handleHideVideo = e => {
		e.preventDefault();

		if (classAssignment === null){
			showSpinner()
			classAssignment = 'null'
			handle_hide_video({
				variables: {
					"type": "video_stream",
					"id": id,
					"classAssignment": classAssignment
				}
			})		
		}else{
			let classAssignmentName = classAssignment.name
			let classAssignmentCampus = classAssignment.campus
			classAssignment = JSON.stringify(classAssignment)

			if(classAssignmentCampus !== null){
				showSpinner()
				handle_hide_video({
					variables: {
						"type": "video_stream",
						"id": id,
						"classAssignment": classAssignment
					}
				})
			}else{
				ToastWarning("Classroom " + classAssignmentName + " is not assigned to a campus")
			}
		}

		setVideo(true)
	}

	const handleShowVideo = e => {
		e.preventDefault();

		if (classAssignment === null){
			showSpinner()
			classAssignment = 'null'
			handle_show_video({
				variables: {
					"type": "video_stream",
					"id": id,
					"classAssignment": classAssignment
				}
			})		
		}else{
			let classAssignmentName = classAssignment.name
			let classAssignmentCampus = classAssignment.campus
			classAssignment = JSON.stringify(classAssignment)

			if(classAssignmentCampus !== null){
				showSpinner()
				handle_show_video({
					variables: {
						"type": "video_stream",
						"id": id,
						"classAssignment": classAssignment
					}
				})
			}else{
				ToastWarning("Classroom " + classAssignmentName + " is not assigned to a campus")
			}
		}

		setVideo(false)
	}

	const handleShutDown = e => {
		e.preventDefault();

		if (classAssignment === null){
			showSpinner()
			classAssignment = 'null'
			handle_shutdown({
				variables: {
					"type": "shutdown",
					"id": id,
					"classAssignment": classAssignment
				}
			})		
		}else{
			let classAssignmentName = classAssignment.name
			let classAssignmentCampus = classAssignment.campus
			classAssignment = JSON.stringify(classAssignment)

			if(classAssignmentCampus !== null){
				showSpinner()
				handle_shutdown({
					variables: {
						"type": "shutdown",
						"id": id,
						"classAssignment": classAssignment
					}
				})
			}else{
				ToastWarning("Classroom " + classAssignmentName + " is not assigned to a campus")
			}
		}

		setVideo(true)
		set_joinedRoom(false)
	}

	const handleLeaveRoom = e => {
		e.preventDefault();

		if (classAssignment === null){
			showSpinner()
			classAssignment = 'null'
			handle_leave_room({
				variables: {
					"type": "stream_off",
					"id": id,
					"classAssignment": classAssignment
				}
			})		
		}else{
			let classAssignmentName = classAssignment.name
			let classAssignmentCampus = classAssignment.campus
			classAssignment = JSON.stringify(classAssignment)

			if(classAssignmentCampus !== null){
				showSpinner()
				handle_leave_room({
					variables: {
						"type": "stream_off",
						"id": id,
						"classAssignment": classAssignment
					}
				})
			}else{
				ToastWarning("Classroom " + classAssignmentName + " is not assigned to a campus")
			}
		}
		set_joinedRoom(false)
	}

	useEffect(() => {
		return () => {
			if (classAssignment === null){
				showSpinner()
				classAssignment = 'null'
				handle_leave_room_effect({
					variables: {
						"type": "stream_off",
						"id": id,
						"classAssignment": classAssignment
					}
				})		
			}else{
				let classAssignmentCampus = classAssignment.campus
				classAssignment = JSON.stringify(classAssignment)
				if(classAssignmentCampus !== null){
					showSpinner()
					handle_leave_room_effect({
						variables: {
							"type": "stream_off",
							"id": id,
							"classAssignment": classAssignment
						}
					})
				}else{}
			}
	
			set_joinedRoom(false)
		}
	}, [])

	return (
		<div className={ style.table_wrapper }>
			<SectionHeader title='Camera Controls' />
			<div className= {style.control_buttons}>
				{ (!joinedRoom && !spinner) ?
					(<div><button onClick={ openModal } style={ customStyles.button }>Join Room</button>
						<Modal
							isOpen={ modalIsOpen }
							onRequestClose={ closeModal }
							contentLabel="Join Room"
							style={ customStyles }
						>
							<div className={ style.panel_row } style={{ marginBottom: 0 }}>
								<div className={ style.panel + ' ' + style.panel_modal }>
									<form onSubmit={ handleSubmit }>
										<div style={{ margin: 'auto'}}>
											<h3>Join Room</h3>
											<table>
												<thead>
													<tr>
														<th>Room ID</th>
														<td><input type="text" value={ roomID } onChange={ e => set_roomID(e.target.value) } /></td>
													</tr>
												</thead>
											</table>
											<div style={{ margin: 'auto', width: '50%'}}>
												<div style={{ display: 'flex'}}>
													<button className={ style.form_button } onClick={closeModal}>Cancel</button>
													<button className={ style.form_button } type="submit">Join</button>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</Modal>
					</div>) : ''
				}
				{ joinedRoom ?
					(<div className= {style.control_buttons}>
						<button type="submit" value="Submit" onClick={handlePlayAudio} style={ customStyles.button }>Play Audio</button>
						<button type="submit" value="Submit" onClick={handleStopAudio} style={ customStyles.button }>Stop Audio</button>
						<form onSubmit={ handleResolution }>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<select id='assign-resolution' value={resolution} style={{ marginLeft: 0 }} onChange={ e => setResolution(e.target.value) }>
									<option value={ -1 }>{ "Resolution" }</option>
									{resolutions.map(resolution => (
										<option key={ resolution } value={ resolution }>{ resolution }</option>
							    ))}
								</select>
								<button type="submit" value="Submit" className={ style.td_button + ' ' + style.submit_text }>Set Resolution</button>
							</div>
						</form>
						{ !audio ? 
							(<button type="submit" value="Submit" onClick={handleMuteAudio} style={ customStyles.button }>Mute Audio</button>) : 
							(<button type="submit" value="Submit" onClick={handleUnMuteAudio} style={ customStyles.button }>Unmute Audio</button>) 
						}
						{ !video ? 
							(<button type="submit" value="Submit" onClick={handleHideVideo} style={ customStyles.button }>Hide Video</button>) : 
							(<button type="submit" value="Submit" onClick={handleShowVideo} style={ customStyles.button }>Show Video</button>) 
						}
						<button type="submit" value="Submit" onClick={handleLeaveRoom} style={ customStyles.button }>Leave Room</button>
						<button type="submit" value="Submit" onClick={handleReboot} style={ customStyles.button }>Reboot</button>
					  	<button type="submit" value="Submit" onClick={handleShutDown} style={ customStyles.button }>Shutdown</button>
					  </div>
					  ) : !spinner ? <p style={{border: "none", display: 'flex', paddingBottom: "10px"}}>Join Room to view camera controls</p> : '' }
				{!spinner && !joinedRoom? <button type="submit" value="Submit" onClick={handleReboot} style={ customStyles.button }>Reboot Camera</button>: '' }
				{!spinner && !joinedRoom? <button type="submit" value="Submit" onClick={handleShutDown} style={ customStyles.button }>Shutdown Camera</button>: '' }
			</div>
			<div style={{maxWidth: "100px", maxHeight: "100px", paddingLeft: "450px"}}>
				{ spinner }
			</div>
		</div>
            
	)
}