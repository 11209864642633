import React from 'react'

const Spinnericon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" style={{background: "none"}} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
<g transform="rotate(0 50 50)">
  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#000000">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8928571428571428s" begin="-0.8184523809523808s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(30 50 50)">
  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e0e0e0">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8928571428571428s" begin="-0.744047619047619s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(60 50 50)">
  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e0e0e0">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8928571428571428s" begin="-0.6696428571428571s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(90 50 50)">
  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e0e0e0">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8928571428571428s" begin="-0.5952380952380952s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(120 50 50)">
  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e0e0e0">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8928571428571428s" begin="-0.5208333333333333s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(150 50 50)">
  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e0e0e0">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8928571428571428s" begin="-0.4464285714285714s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(180 50 50)">
  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e0e0e0">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8928571428571428s" begin="-0.3720238095238095s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(210 50 50)">
  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e0e0e0">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8928571428571428s" begin="-0.2976190476190476s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(240 50 50)">
  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e0e0e0">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8928571428571428s" begin="-0.2232142857142857s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(270 50 50)">
  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e0e0e0">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8928571428571428s" begin="-0.1488095238095238s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(300 50 50)">
  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e0e0e0">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8928571428571428s" begin="-0.0744047619047619s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(330 50 50)">
  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e0e0e0">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8928571428571428s" begin="0s" repeatCount="indefinite"></animate>
  </rect>
</g>
</svg>
    )
}

export default Spinnericon
