import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';
import {useQuery, useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import style from '../../../styling/Panels.module.css';
import Modal from 'react-modal';

import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

var moment = require('moment')
moment().format()

Modal.setAppElement('#root')

const GET_CLASSROOMS = gql`
	query get_classrooms {
		classrooms {
            id
            name
		}
	}
`;

const POST_LECTURE = gql`
    mutation postLectureSchedule($class_id: ID, $classroom_id: ID, $start_date: String, $lecture_length: Int, $end_date: String, $stream_permission: Boolean) {
        createLectureSchedule(
			class_id: $class_id, 
			classroom_id: $classroom_id, 
			start_date: $start_date,
			lecture_length: $lecture_length,
			end_date: $end_date, 
			stream_permission: $stream_permission
        ) {
            id
        }
    }
`;

function Toast(message) {
	toast.success(message);
}

function ErrorToast(message) {
	toast.error(message);
}

const customStyles = {
	content : {
	    top                   : '30%',
	    left                  : '50%',
	    right                 : 'auto',
	    bottom                : 'auto',
	    marginRight           : '-50%',
	    transform             : 'translate(-50%, -50%)',
	    border				  : '1px solid #878787',
		padding				  : 0,
		boxShadow             : '0px 8px 15px rgba(181, 181, 181, 0.8)',
	}
}

function Component (props) {
	const {data, loading, error} = useQuery(
		GET_CLASSROOMS,
		{variables: {class_id: props.class.id}}
	)
	
	const [post] = useMutation(POST_LECTURE, {
		onError(e) {
			ErrorToast("Could not create lectures")
		},
		onCompleted(r) {
			Toast("Lectures created")
			closeModal()
		}
	})
    
	const [classroom, setClassroom] = useState(0)
	const [startDate, setStartDate] = useState(props.class ? moment(props.class.start_date).format('lll') : '')
	const [lectureLength, setLectureLength] = useState(120)
	const [streamPermission, setStreamPermission] = useState(true)
	const [endDate, setEndDate] = useState(props.class ? moment(props.class.end_date).format('lll') : '')
	
	const [modalIsOpen, setIsOpen] = React.useState(false)
	function openModal() {
		setIsOpen(true)
	}
	function closeModal () {
		setIsOpen(false)
	}

	const [ redirect ] = React.useState(false);


	const handleSubmit = e => {
		e.preventDefault()

		if (classroom !== 0) {
			post({ 
				variables: {
					class_id: props.class.id, 
					classroom_id: Number(classroom), 
					start_date: moment.tz(startDate, moment.ISO_8601, "America/Toronto").utc(),
					lecture_length: Number(lectureLength),
					end_date: endDate, 
					stream_permission: streamPermission
				}
			})
		}
		else if (classroom === 0) {
			ErrorToast("Please select a classroom")
		}
	}

	if(loading || error) {
		return (
			null
		)
	}
	else {
		return (
			<>
				<button onClick={ openModal } className={ style.standalone_button }>Schedule Lectures</button>
				<Modal
					isOpen={modalIsOpen}
					onRequestClose={closeModal}
					contentLabel="Schedule Lectures"
					style={customStyles}
				>
					<div className={style.panel_row} style={{ marginBottom: 0 }}>
						<div className={style.panel + ' ' + style.panel_modal}>
							<h3>Schedule Lectures for {props.class.code}</h3>
							<form onSubmit={handleSubmit}>
								<table>
									<thead>
										<tr>
											<th>First Lecture Date/Time</th>
											<td><input type="datetime-local" value={startDate} onChange={e => setStartDate(e.target.value)} required /></td>
										</tr>
										<tr>
											<th>Lecture Length (minutes)</th>
											<td><input type="number" value={lectureLength} onChange={e => setLectureLength(e.target.value)} required /></td>
										</tr>
										<tr>
											<th>Last Lecture Date</th>
											<td><input type="date" value={endDate} onChange={e => setEndDate(e.target.value)} required /></td>
										</tr>
										<tr>
											<th>Classroom</th>
											<td>
												<select value={classroom} onChange={e => setClassroom(e.target.value)}>
													<option value={0}>Select Classroom</option>
													{
														data.classrooms ?
															data.classrooms.map(classroom => (
																<option key={classroom.id} value={classroom.id}>
																	{classroom.name}
																</option>
															)) :
															null
													}
												</select>
											</td>
										</tr>
										<tr>
											<th>Stream Permission</th>
											<td><input type="checkbox" value={streamPermission} checked={streamPermission} onChange={e => setStreamPermission(streamPermission ? false : true)} /></td>
										</tr>
									</thead>
								</table>
								<div style={{ margin: 'auto', width: '50%'}}>
									<div style={{ display: 'flex'}}>
										<button className={ style.form_button } onClick={closeModal}>Cancel</button>
										<button className={ style.form_button } type="submit">Schedule</button>
									</div>
								</div>
							</form>
						</div>
					</div>
					{ redirect ? <Redirect to={ "/classes" } /> : null }
				</Modal>
			</>
		)
	}	
}

export default Component