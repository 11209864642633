import React from 'react';
import {
	Link
} from "react-router-dom";
import style from '../../../styling/Panels.module.css';
import EditCreateCampus from './EditCreateCampus'

var moment = require('moment');
moment().format();
moment.defaultFormat = "MMMM Do YYYY, h:mm:ss a";

function ClassroomList (props) {
    console.log(props)
    return (
        <ul style={{listStyleType: "none", marginLeft: "0px", paddingLeft: "0px"}}>
            {props.classroomList.map(classroom => 
                <li><a href={`/classrooms/${classroom.id}`}>{ classroom.name }</a></li>          
            )}
        </ul>
    )
}

export default function CampusInfo (props) {
	return (
		<div style={{ display: 'flex', alignItems: "center" }}>
			<div className={ style.table_wrapper } style={{ width: "100%" }}>
				<table>
					<thead>
						<tr>
							<th>Campus Name</th>
							<th>Classrooms</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td style={{verticalAlign: "top"}}>{ props.campus.name }</td>
							<td style={{verticalAlign: "top"}}>
								{
                                    props.classrooms ?
                                        (
                                        <ClassroomList classroomList = { props.classrooms }/>
                                        )
										:
										"N/A"
								}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<EditCreateCampus campus={ props.campus } usage={'Edit'} />
		</div>
	);
}