import React, { useState } from 'react';

import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import SectionHeader from '../../../components/sectionheader';
import { Redirect } from 'react-router-dom'
import "react-toastify/dist/ReactToastify.css";
import style from '../../../styling/Panels.module.css';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';

const GET_CAMPUS = gql`
  query campus_list {
      campuses {
          id
          name
      }
  }
`;

const ASSIGN_CAMPUS = gql`
    mutation assignClassroomToCampus($classroom_id: ID!, $campus_id: ID!) {
        assignClassroomToCampus(
            classroom_id: $classroom_id,
            campus_id: $campus_id,
        ) {
            id
        }
    }
`

/**
 * @return {null}
 */
function UnassignedCampus(props) {
	if (props.campus) {
		return <option value={ props.campus.id }>{ props.campus.name}</option>
	}
	return null;
}

function Toast(message) {
	toast.success(message);
}

export default function AssignNBotCampus(props) {
	const [campus, setCampus] = useState(-1);

	const { data, loading, error } = useQuery(GET_CAMPUS, {
		pollInterval: 1000 * 15,
    })

    const [assign_classroom] = useMutation(ASSIGN_CAMPUS, {
		onCompleted(r) {
			Toast("Classroom Assigned to Campus")
		}
    })
    
    const handleSubmit = e => {
        e.preventDefault()
		assign_classroom({ 
			variables: {
                classroom_id: props.campus_id.id,
                campus_id: campus,
			}
		})
	}

	if (loading) {
		return (
			<div>
				<h3>Loading</h3>
			</div>
		)
	}
	else if (error) {
		return (
			<Redirect to='/login' />
		)
	}
	else {
		return (
			<>
                <div style={{display: "flex", alignItems: "center"}}>
                    <div>
                        <SectionHeader title='Assign to Campus' />
                        <form onSubmit={ handleSubmit }>
                            <div style={{ margin: '0 auto', display: 'flex', width: '100%', alignItems: 'center' }}>
                                <select id='assign-nbot' value={ campus } style={{ marginLeft: 0 }} onChange={ e => setCampus(Number(e.target.value)) } >
                                    <option value={ -1 }>{ "Campuses" }</option>
                                    {data.campuses.map(campus => (
                                        <UnassignedCampus campus={ campus } key={ campus.id } />
                                    ))}
                                </select>
                                <button type="submit" value="Submit" className={ style.td_button + ' ' + style.submit_text }>Assign Campus</button>
                            </div>
                        </form>
                    </div>
                </div>
            </>
            
		);
	}
}