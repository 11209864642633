import React, { useState } from 'react';
import PageHeader from '../../components/pageheader';
import TypeQRScanner from './components/TypeQRScanner';

export const App = () => {
    return (
		<div style ={{ marginLeft: "50px"}}>
			<PageHeader title="Scanner" />
            <TypeQRScanner />
		</div>
	);
};

export default App;