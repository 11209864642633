import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import style from '../../../styling/Panels.module.css'
import Modal from 'react-modal'

import { toast } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css"

Modal.setAppElement('#root')

const UPDATE_USER = gql`
  	mutation updateuser($id: ID!, $first_name: String!, $last_name: String!, $username: String!, $email: String!) {
		updateUser(
	  		id: $id, 
	  		first_name: $first_name, 
	  		last_name: $last_name, 
	  		username: $username,
		  	email: $email
		) {
            token
        }
  	}
`;

function ErrorToast(message) {
	toast.error(message)
}

const customStyles = {
	content : {
	    top                   : '30%',
	    left                  : '50%',
	    right                 : 'auto',
	    bottom                : 'auto',
	    marginRight           : '-50%',
	    transform             : 'translate(-50%, -50%)',
	    border				  : '1px solid #878787',
		padding				  : 0,
		boxShadow             : '0px 8px 15px rgba(181, 181, 181, 0.8)',
	}
};

export default function EditStudentModal (props) {
	let { id } = useParams()
	const [ modalIsOpen, setIsOpen ] = React.useState(false)
    
	function openModal() {
	  setIsOpen(true);
	}

	function closeModal () {
		setIsOpen(false);
	}
    
	const [ first_name, set_first_name] = useState(props.user ? props.user.first_name : '');
	const [ last_name, set_last_name] = useState(props.user ? props.user.last_name : '');
	const [ username, set_username] = useState(props.user ? props.user.username : '');
	const [ email, set_email] = useState(props.user ? props.user.email : '');

	const [updateUser] = useMutation(UPDATE_USER, {
		onError(e) {
			ErrorToast("Could not update")
			console.log(e)
		},
	});

	const handleSubmit = e => {
		updateUser({
			variables: {
				id,
				first_name,
				last_name,
				username,
				email
			}
		})
	}

	return (
		<>
			<button onClick={ openModal } className={ style.td_button + ' ' + style.submit_text }>Update</button>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel="Update Account"
				style={ customStyles }
			>
				<div className={ style.panel_row } style={{ marginBottom: 0 }}>
					<div className={ style.panel + ' ' + style.panel_modal }>
						<h3>Update User Info</h3>
						<form onSubmit={ handleSubmit }>
							<table>
								<thead>
									<tr>
										<th>First Name</th>
										<td><input type="text" value={ first_name } onChange={ e => set_first_name(e.target.value) } /></td>
									</tr>
									<tr>
										<th>Last Name</th>
										<td><input type="text" value={ last_name } onChange={ e => set_last_name(e.target.value) } /></td>
									</tr>
									<tr>
										<th>Username</th>
										<td><input type="text" value={ username } onChange={ e => set_username(e.target.value) } /></td>
									</tr>
									<tr>
										<th>Email</th>
										<td><input type="text" value={ email } onChange={ e => set_email(e.target.value) } /></td>
									</tr>
								</thead>
							</table>
							<div style={{ margin: 'auto', width: '50%'}}>
								<div style={{ display: 'flex'}}>
									<button className={ style.form_button } onClick={closeModal}>Cancel</button>
									<button className={ style.form_button } type="submit">Update</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</Modal>
		</>
	);
}