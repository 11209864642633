import React, { useState, useEffect } from 'react'
import {Link, Redirect} from "react-router-dom"
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import style from '../../styling/Panels.module.css'
import PageHeader from '../../components/pageheader'
import CreateClass from './components/EditClass'
import Cookies from 'universal-cookie'

var moment = require('moment')
moment().format()
moment.defaultFormat = "MMMM Do YYYY, h:mm:ss a"

const GET_CLASSES = gql`
  	query class_list {
		classes {
			id
			name
			code
			start_date
			end_date
		}
	}`

/**
 * @return {null}
 */
function Class (props) {
	return (
		<tr>
			<td><Link to={`/classes/${ props.class.id }`}>{props.class.name}</Link></td>
			<td><Link to={`/classes/${ props.class.id }`}>{props.class.code}</Link></td>
			<td>{moment.unix(props.class.start_date / 1000).format()}</td>
			<td>{moment.unix(props.class.end_date / 1000).format()}</td>
		</tr>
	)
}

function Component(props) {
	const { data, loading, error, refetch } = useQuery(GET_CLASSES, {
		pollInterval: 1000 * 10,
	})

	const cookies = new Cookies()

	if(cookies.get('new-login')) {
		cookies.remove('new-login')
		window.location.reload()
	}


	if (loading) {
		return (
			<h1>Loading</h1>
		)
	}
	else if (error) {
		console.log(error)
		return (
			<Redirect to='/login' />
		)
	}
	else {
		const sort_by = (field, reverse, primer) => {
			const key = primer ?
			  function(x) {
					return primer(x[field])
			  } :
			  function(x) {
					return x[field]
			  }
		  
			reverse = !reverse ? 1 : -1;
		  
			return function(a, b) {
				return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
			}
		}

		const classes = data.classes.sort(sort_by('code', false, (a) => a.toUpperCase()))

		return (
			<div>
				<PageHeader title="Classes" />
				<div className={ style.panel_row }>	
					<div className={ style.panel + ' ' + style.panel_single } >
						<div className={ style.table_wrapper }>
							<table>
								<thead>
									<tr>
										<th>Name</th>
										<th>Code</th>
										<th>Start Date</th>
										<th>End Date</th>
									</tr>
								</thead>
								<tbody>
									{
										classes.map(class_ => (
											<Class key={ class_.id } class={ class_ } />
										))
									}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div>
					<CreateClass student={ null } usage={'Create'} />
				</div>
			</div>
		)
	}
}

export default Component;