import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';
import {useQuery, useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import style from '../../../styling/Panels.module.css';
import Modal from 'react-modal';

import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

var moment = require('moment')
moment().format()

Modal.setAppElement('#root')

const GET_CLASSROOMS = gql`
	query get_classrooms {
		classrooms {
            id
            name
		}
	}
`;

const PUT_LECTURE = gql`
    mutation updateBooking($id: ID, $class_id: ID, $classroom_id: ID, $start_date: String, $end_date: String, $stream_permission: Boolean) {
        updateBooking(
            id: $id,
			class_id: $class_id, 
			classroom_id: $classroom_id, 
			start_date: $start_date,
			end_date: $end_date, 
			stream_permission: $stream_permission
        ) {
            id
        }
    }
`;

function Toast(message) {
	toast.success(message);
}

function ErrorToast(message) {
	toast.error(message);
}

const customStyles = {
	content : {
	    top                   : '30%',
	    left                  : '50%',
	    right                 : 'auto',
	    bottom                : 'auto',
	    marginRight           : '-50%',
	    transform             : 'translate(-50%, -50%)',
	    border				  : '1px solid #878787',
		padding				  : 0,
		boxShadow             : '0px 8px 15px rgba(181, 181, 181, 0.8)',
	}
}

function Component (props) {	
	const {data, loading, error} = useQuery(
		GET_CLASSROOMS
	)

	const [put] = useMutation(PUT_LECTURE, {
		onError(e) {
			ErrorToast("Could not edit lecture")
		},
		onCompleted(r) {
			Toast("Lecture updated")
			closeModal()
		}
	})
    
	const [classroom, setClassroom] = useState(0)
	const [startDate, setStartDate] = useState(props.lecture ? moment(props.lecture.start_time).format('YYYY-MM-DDThh:mm') : '')
	const [streamPermission, setStreamPermission] = useState(true)
	const [endDate, setEndDate] = useState(props.lecture ? moment(props.lecture.end_time).format('YYYY-MM-DDThh:mm') : '')
	
	const [modalIsOpen, setIsOpen] = React.useState(false)
	function openModal() {
		setIsOpen(true)
	}
	function closeModal () {
		setIsOpen(false)
	}

	const [ redirect, setRedirect ] = React.useState(false);


	const handleSubmit = e => {
		e.preventDefault()

		console.log(startDate)
		if (classroom !== 0) {
			put({ 
				variables: {
					id: props.lecture.id,
					class_id: Number(props.class_id), 
					classroom_id: Number(classroom), 
					start_date: startDate,
					end_date: endDate, 
					stream_permission: streamPermission
				}
			})
		}
	}
    
	if (loading || error) {
		return null
	}
	else {
		return (
			<>
				<button onClick={ openModal }>Edit</button>
				<Modal
					isOpen={modalIsOpen}
					onRequestClose={closeModal}
					contentLabel="Schedule Lectures"
					style={customStyles}
				>
					<div className={style.panel_row} style={{ marginBottom: 0 }}>
						<div className={style.panel + ' ' + style.panel_modal}>
							<h3>Edit Lecture</h3>
							<form onSubmit={handleSubmit}>
								<table>
									<thead>
										<tr>
											<th>Lecture Start</th>
											<td><input type="datetime-local" value={startDate} onChange={e => setStartDate(e.target.value)} required /></td>
										</tr>
										<tr>
											<th>Lecture End</th>
											<td><input type="datetime-local" value={endDate} onChange={e => setEndDate(e.target.value)} required /></td>
										</tr>
										<tr>
											<th>Classroom</th>
											<td>
												<select value={classroom} onChange={e => setClassroom(e.target.value)}>
													<option value={0}>Select Classroom</option>
													{
														data.classrooms.map(classroom => (
															<option key={classroom.id} value={classroom.id}>
																{classroom.name}
															</option>
														))
													}
												</select>
											</td>
										</tr>
										<tr>
											<th>Stream Permission</th>
											<td><input type="checkbox" value={streamPermission} checked={streamPermission} onChange={e => setStreamPermission(streamPermission ? false : true)} /></td>
										</tr>
									</thead>
								</table>
								<div style={{ margin: 'auto', width: '50%'}}>
									<div style={{ display: 'flex'}}>
										<button className={ style.form_button } onClick={closeModal}>Cancel</button>
										<button className={ style.form_button } type="submit">Update</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</Modal>
			</>
		)
	}
}

export default Component