import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import style from '../../../styling/Panels.module.css';
import Modal from 'react-modal';

import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

Modal.setAppElement('#root')

const PUT_CLASS = gql
`
mutation put_class($id: ID!, $name: String!, $code: String!, $start_date: String!, $end_date: String!) {
	updateClass(
		id: $id,
		name: $name,
		code: $code,
		start_date: $start_date,
		end_date: $end_date
	) {
		id
	}
}
`

const POST_CLASS = gql
`
mutation post_class($name: String!, $code: String!, $start_date: String!, $end_date: String!) {
	createClass(
		name: $name,
		code: $code,
		start_date: $start_date,
		end_date: $end_date
	) {
		id
	}
}
`

function Toast(message) {
	toast.success(message);
}

const customStyles = {
	content : {
	    top                   : '30%',
	    left                  : '50%',
	    right                 : 'auto',
	    bottom                : 'auto',
	    marginRight           : '-50%',
	    transform             : 'translate(-50%, -50%)',
	    border				  : '1px solid #878787',
		padding				  : 0,
		boxShadow             : '0px 8px 15px rgba(181, 181, 181, 0.8)',
	}
}

function Component (props) {
	const [ modalIsOpen, setIsOpen ] = React.useState(false)
    
	function openModal() {
	  setIsOpen(true)
	}

	function closeModal () {
		setIsOpen(false)
	}

	function clearFields() {
		setName()
		setCode()
		setStartDate()
		setEndDate()
	}

	const [name, setName] = useState(props.class ? props.class.name : '')
	const [code, setCode] = useState(props.class ? props.class.code : '')
	const [startDate, setStartDate] = useState(props.class ? props.class.start_date : '')
	const [endDate, setEndDate] = useState(props.class ? props.class.end_date : '')

	const [redirect, setRedirect] = React.useState(false)

	const [putNbot] = useMutation(PUT_CLASS)
	const [postNbot] = useMutation(POST_CLASS)

	const handleSubmit = e => {
		e.preventDefault()
		if (props.usage === 'Create') {
			console.log(String(startDate))
			console.log(String(endDate))
			postNbot({ 
				variables: {
					name: name,
					code: code,
					start_date: startDate,
					end_date: endDate
				}
			})
			Toast("Class Created")
			closeModal()
			clearFields()
		}
		else {
			putNbot({ 
				variables: {
					id: props.class.id,
					code: code,
					name: name,
					start_date: startDate,
					end_date: endDate
				}
			})
			Toast("Class Edited")
			return setRedirect(true)
		}
	}

	return (
		<>
			{ 
				(props.usage === 'Create') ? 
					<button onClick={ openModal } className={ style.standalone_button }>{ props.usage }</button> :
					<button onClick={ openModal } className={ style.td_button + ' ' + style.submit_text }>{ props.usage }</button> 
			}
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel="Update Class"
				style={ customStyles }
			>
				<div className={ style.panel_row } style={{ marginBottom: 0 }}>
					<div className={ style.panel + ' ' + style.panel_modal }>
						<h3>{ props.usage } Class</h3>
						<form onSubmit={ handleSubmit }>
							<table>
								<thead>
									<tr>
										<th>Name</th>
										<td><input type="text" value={name} onChange={e => setName(e.target.value)} required /></td>
									</tr>
									<tr>
										<th>Code</th>
										<td><input type="text" value={code} onChange={e => setCode(e.target.value)} required /></td>
									</tr>
									<tr>
										<th>Start Date</th>
										<td><input type="date" value={startDate} onChange={e => setStartDate(e.target.value)} required /></td>
									</tr>
									<tr>
										<th>End Date</th>
										<td><input type="date" value={endDate} onChange={e => setEndDate(e.target.value)} required /></td>
									</tr>
								</thead>
							</table>
							<div style={{ margin: 'auto', width: '50%'}}>
								<div style={{ display: 'flex'}}>
									<button className={ style.form_button } onClick={closeModal}>Cancel</button>
									<button className={ style.form_button } type="submit">{ props.usage }</button>
								</div>
							</div>
						</form>
					</div>
				</div>
				{ redirect ? <Redirect to={ "/classes" } /> : null }
			</Modal>
		</>
	);
}

export default Component