import React, { useState } from 'react';

import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import style from '../../../styling/Panels.module.css';
import SectionHeader from '../../../components/sectionheader';
import { Redirect } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import mqtt from 'mqtt'

const GET_CLASSROOM = gql`
  	query campus($id: ID!) {
		campus(id: $id) {
			id
            name
            classrooms{
                id
                name
            }
		}
	}
`;

const ASSIGN_CLASSROOM = gql`
    mutation assignClassroomToNBot($nbot_id: ID!, $classroom_id: ID!, $campus_id: ID!, $angle: String!, $classname: String!, $inspected: String!, $building: String!, $classAssignment: String!) {
        assignClassroomToNBot(
			nbot_id: $nbot_id,
            classroom_id: $classroom_id,
            campus_id: $campus_id,
            angle: $angle,
            classname: $classname,
            inspected: $inspected,
            building: $building,
            classAssignment: $classAssignment
        ) {
            id
        }
    }
`

/**
 * @return {null}
 */
function UnassignedClassroom(props) {
	if (props.classroom) {
		return <option value={ props.classroom.id }>{ props.classroom.name}</option>
	}
	return null;
}

function Toast(message) {
	toast.success(message);
}

export default function AssignNBotCampus(props) {
    const [classroom, setClassroom] = useState(-1);
    
    const [angle, setAngle] = useState("");

	const { data, loading, error } = useQuery(
		GET_CLASSROOM,
        { variables: { id: props.id }, 
        pollInterval: 1000 * 2}
	);

	const [assign_classroom] = useMutation(ASSIGN_CLASSROOM, {
		onCompleted(r) {
            Toast("nBot Assigned to Classroom")
		}
    })
    
    const setData = classroom => {
        var classroomName = '';
        for (let i = 0; i < (data.campus.classrooms).length; i++){
            if(classroom == data.campus.classrooms[i].id){
                classroomName = data.campus.classrooms[i].name
            }
        }
        return classroomName;
    }

	const handleSubmit = async e => {
        e.preventDefault()

        if (props.classAssignment == null){
            var classAssignment = 'null'
        }else{
            var classAssignment = JSON.stringify(props.classAssignment)
        }

		if (classroom && classroom != "") {
			await assign_classroom({ 
				variables: {
					nbot_id: props.nbot_id,
                    classroom_id: classroom,
                    campus_id: props.id,
                    angle: angle,
                    classname: setData(classroom),
                    inspected: 'true',
                    building: data.campus.name,
                    classAssignment: classAssignment
				}
            })
        }
	}

	if (loading) {
		return (
			<div>
				<h3>Loading</h3>
			</div>
		)
	}
	else if (error) {
		return (
			<Redirect to='/login' />
		)
	}
	else {
		return (
			<>
                <div>
                    <SectionHeader title='Assign to Classroom' />
                    <form onSubmit={ handleSubmit }>
                        <div style={{ margin: '0 auto', display: 'flex', width: '100%', alignItems: 'center' }}>
                            <select id='assign-nbot' value={ classroom } style={{ marginLeft: 0 }} onChange={ e => setClassroom(Number(e.target.value)) } >
                                <option value={ -1 }>{ "Classrooms" }</option>
                                {data.campus.classrooms.map(classroom => (
                                    <UnassignedClassroom classroom={ classroom } key={ classroom.id } />
                                ))}
                            </select>
                            <input style={{padding: "4.2px", marginLeft: "10px"}} placeholder="Enter Angle" onKeyUp={ e => setAngle(e.target.value)}/>
                            <button type="submit" value="Submit" className={ style.td_button + ' ' + style.submit_text }>Assign</button>
                        </div>
                    </form>
                </div>
            </>
		);
    }
}