import React from 'react';
import {
	Switch,
	Route,
} from "react-router-dom";
import Index from './index';
import Details from './detail';


export default function App() {
	return (
		<div className="App">
			<Switch>
				<Route exact path="/campus" component={Index} />
				<Route path="/campus/:id" component={Details} />
				<Route path="/" component={Index} />
			</Switch>
		</div>
	);
}