import React, { useState } from 'react';
import QrReader from 'react-camera-qr'
import useSound from 'use-sound';
import beep from '../beep-07.mp3';
import AddCamerasToPackage from './AddCamerasToPackage';

export default function TypeQRScanner (){
    const [delay, setDelay] = useState(100)
    const [result, setResult] = useState("Hold QR Code Stready and Clear to Scan")
    const [ redirect, setRedirect ] = React.useState(false);
    const [play, { stop }] = useSound(
        beep,
        { volume: 0.5 }
      );
    const [scanned, setScanned] = useState(false)
    const [packageValue, setPackage] = useState(false)
    // const []
    
    const handlePackageScan = (data) => {
        if(data == null){
            setResult(result)
        }else{
            setResult(data)
            setScanned(true)
        }
    }
    
    const handlePackageError = (err) => {
        console.log("Error: ", err)
    }

    const handleCameraScan = (data) => {
        if(data == null){
            setResult(result)
        }else{
            setResult(data)
            setScanned(true)
        }
    }
    
    const handleCameraError = (err) => {
        console.log("Error: ", err)
    }

    const handleSubmit = e => {
        e.preventDefault()
        console.log(packageValue)
		console.log("Clicked")
    }
    
    const handlePackageScanner = () => {
        console.log("Handle Package")
        play();
    }

    const handleCameraScanner = () => {
        console.log("Handle Package")
        play();
    }


    const previewStyle = {
        height: 420,
        width: 320,
        display: 'flex',
        justifyContent: "center"
    }

    const camStyle = {
        display: 'flex',
        justifyContent: "center",
        marginTop: '0px'
    }

    const textStyle = {
        fontsize: '30px',
        textAlign: 'center',
        marginTop: '0px'
    }
    return(
        <>
            <form onSubmit={ handleSubmit } style={{ marginTop: "10px"}}>
                <div style={ camStyle }>
                    <label>Select Delivery Type</label>
                    <select style ={{ marginBottom: "5px"}} id='assign-type' value={ packageValue } style={{ marginLeft: "5px", width: '100px' }} onChange={ e => setPackage(e.target.value) }>
                        <option value={ -1 }>{ "Select" }</option>
                        <option value='Package'>Package</option>
                        <option value='Camera'>Camera</option>
                    </select>
                </div>
            </form>
            { packageValue == 'Package' ? (
                <div>
                    <div>
                        <p style={ textStyle }>Scan Package QR code</p>
                        <div style={ camStyle }>
                            <QrReader
                                delay={delay}
                                style={{ width: '100%' }}
                                onError={handlePackageError}
                                onScan={handlePackageScan}
                            />
                        </div>
                        <div>
                            <p style={ textStyle }>{result}</p>
                            {scanned ? <button style={
                                {   backgroundColor: "#adff29", 
                                    borderRadius: "10px", 
                                    width: "60px",
                                    fontsize: '30px',
                                    textAlign: 'center',
                                    margin: "0 auto",
                                    display: "block"
                                }}
                                onClick={handlePackageScanner}>Scan</button> : '' }
                        </div>
                    </div>
                    <AddCamerasToPackage />
                </div>
            ) : packageValue == 'Camera' ?
            <div>
                <p style={ textStyle }>Scan Camera QR code</p>
                <div style={ camStyle }>
                    <QrReader
                        delay={delay}
                        style={{ width: '100%' }}
                        onError={handleCameraError}
                        onScan={handleCameraScan}
                    />
                </div>
                <div>
                    <p style={ textStyle }>{result}</p>
                    {scanned ? <button style={
                            {   backgroundColor: "#adff29", 
                                borderRadius: "10px", 
                                width: "60px",
                                fontsize: '30px',
                                textAlign: 'center',
                                margin: "0 auto",
                                display: "block"
                            }}
                            onClick={handleCameraScanner} >Scan</button> : '' }
                </div>
            </div>: ''
            }
        </>

    );

}