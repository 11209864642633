import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom"
import panelStyle from '../../../styling/Panels.module.css'
import chatStyle from '../../../styling/Chat.module.css'
var StompJs = require('@stomp/stompjs')

function Component() {
	const {id} = useParams()
	const [firstRender, setFirstRender] = useState(true)
	const [numAnswers, setNumAnswers] = useState(1)
	const [answer, setAnswer] = useState()
	const [answers, setAnswers] = useState([''])
	const [question, setQuestion] = useState()
	const [sent, setSent] = useState(false)
	
	const client = new StompJs.Client({
		brokerURL: "ws://localhost:15674/ws",
		connectHeaders: {
		  login: "guest",
		  passcode: "guest"
		},
		debug: function (str) {
		  // console.log(str);
		},
		reconnectDelay: 5000,
		heartbeatIncoming: 4000,
		heartbeatOutgoing: 4000
	});

	client.activate()
	
	useEffect(() => {
		if (firstRender) {
			client.onConnect = function(frame) {
				// console.log('Stan Luda')				
			}
			setFirstRender(false)
		}
	})

	client.onStompError = function (frame) {
		// Will be invoked in case of error encountered at Broker
		// Bad login/passcode typically will cause an error
		// Complaint brokers will set `message` header with a brief message. Body may contain details.
		// Compliant brokers will terminate the connection after any error
		console.log('Broker reported error: ' + frame.headers['message']);
		console.log('Additional details: ' + frame.body);
	};

	function SendQuestion() {
		client.publish({destination: '/topic/georgebrown.' + id + '.polls.questions', body: 
			JSON.stringify({
				'type': 'poll',
				'id': Math.floor(Math.random() * 100),
				'question': question,
				'answers': answers
			})
		})
		// Clear state
		setAnswers([''])
		setQuestion('')
	}

	function AddAnswer() {
		let newArr = [...answers, '']
		setAnswers(newArr)
	}

	const updateFieldChanged = index => e => {
		let newArr = [...answers]
		newArr[index] = e.target.value
		setAnswers(newArr)
	}

	return (
		<div className={panelStyle.panel + ' ' + panelStyle.panel_triple + ' ' + chatStyle.chatbox}>
			<h2>Create Poll</h2>
			<div className={chatStyle.message_panel}>
				<input type="text" placeholder="Question..." value={question} onChange={e => setQuestion(e.target.value)} />
				{
					answers.map((answerInput, index) => {
						return <input key={index} type="text" placeholder="Answers..." value={answers[index]} onChange={updateFieldChanged(index)} />
					})
				}
				<button onClick={AddAnswer}>Add another answer</button>
				<button onClick={SendQuestion}>Send Poll</button>
			</div>
		</div>
	)
}
// <button onClick={client.publish({destination: '/topic/georgebrown.' + id + '.polls.questions', body: 'no answer'})}>Hello World</button>
export default Component