import React from 'react'
import { useParams } from "react-router-dom"
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import PageHeader from '../../components/pageheader'
import style from '../../styling/Panels.module.css'
import LectureInfo from './components/LectureInfo'
import RabbitChat from './components/RabbitChat'
import RabbitPolls from './components/RabbitPolls'
import RabbitPollAnswers from './components/RabbitPollAnswers'
import RabbitCreatePolls from './components/RabbitCreatePolls'

const GET_LECTURE = gql`
query booking($id: ID!) {
	booking(id: $id) {
		id
		start_time
		end_time
		stream_permission
		class {
			id
			name
			code
		}
		classroom {
			id
			name
			nBot {
				id
				url
			}
		}
	}
}`

function Component() {
	let { id } = useParams()
	const { data, loading, error } = useQuery(
		GET_LECTURE,
		{
			variables: { id },
			pollInterval: 1000 * 2
		}
	)

	if (loading) {
		return (
			<div>
				<PageHeader title="Loading" />
			</div>
		)
	}
	else if (error) {
		console.log(error)
		return (
			<div>
				<PageHeader title="Error" />
			</div>
		)
	}
	else {
		return (
			<div>
				<PageHeader title={data.booking.class.name + ' (' + data.booking.class.code + ')'} />

				<div className={ style.panel_row }>
					<div className={ style.panel + ' ' + style.panel_single }>
						<LectureInfo lecture={ data.booking } />
					</div>
				</div>
				
				<div className={ style.panel_row }>
					<RabbitChat />
					{
						/*
					<RabbitPolls />
					<RabbitPollAnswers />
						*/
					}
					
				</div>
				{
					/*
				<div className={style.panel_row}>
					<RabbitCreatePolls />
				</div>
					*/
				}


			</div>
		)
	}
}

export default Component