import React, { useState } from 'react';

import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import SectionHeader from '../../../components/sectionheader';
import { Redirect } from 'react-router-dom'
import "react-toastify/dist/ReactToastify.css";
import AssignNbotClassroom from './AssignNbotClassroom'

const GET_CAMPUS = gql`
  query campus_list {
      campuses {
          id
          name
      }
  }
`;

/**
 * @return {null}
 */
function UnassignedCampus(props) {
	if (props.campus) {
		return <option value={ props.campus.id }>{ props.campus.name}</option>
	}
	return null;
}

export default function AssignNBotCampus(props) {
	const [campus, setCampus] = useState(-1);

	const { data, loading, error } = useQuery(GET_CAMPUS, {
		pollInterval: 1000 * 15,
	})

	if (loading) {
		return (
			<div>
				<h3>Loading</h3>
			</div>
		)
	}
	else if (error) {
		return (
			<Redirect to='/login' />
		)
	}
	else {
		return (
			<>
                <div style={{display: "flex", alignItems: "center"}}>
                    <div>
                        <SectionHeader title='Launch Camera Assignment' />
                        <form>
                            <div style={{ margin: '0 auto', display: 'flex', width: '100%', alignItems: 'center' }}>
                                <select id='assign-nbot' value={ campus } style={{ marginLeft: 0 }} onChange={ e => setCampus(Number(e.target.value)) } >
                                    <option value={ -1 }>{ "Campuses" }</option>
                                    {data.campuses.map(campus => (
                                        <UnassignedCampus campus={ campus } key={ campus.id } />
                                    ))}
                                </select>
                            </div>
                        </form>
                    </div>
                    {(campus !== -1) ? 
                    <AssignNbotClassroom id = {campus} nbot_id = {props.nBot_id} classAssignment={props.classAssignment} /> : "" }
                </div>
            </>
		);
	}
}