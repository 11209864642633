import React from 'react';
import EditUserModal from './EditUser';
import style from '../../../styling/Panels.module.css';

var moment = require('moment');
moment().format();
moment.defaultFormat = "MMMM Do YYYY";

export default function StudentInfo (props) {
	return (
		<div style={{ display: 'flex', alignItems: "center" }}>
			<div className={ style.table_wrapper } style={{ width: "100%" }}>
				<table>
					<thead>
						<tr>
							<th>First Name</th>
							<th>Last Name</th>
							<th>Username</th>
							<th>Email</th>
							<th>Date Registered</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{ props.user.first_name }</td>
							<td>{ props.user.last_name }</td>
							<td>{ props.user.username }</td>
							<td>{ props.user.email }</td>
							<td>{ moment.unix(props.user.created_date / 1000).format() }</td>
						</tr>
					</tbody>
				</table>
			</div>
			<EditUserModal user={ props.user } />
		</div>
	);
}