import React, { useState } from 'react';
import {
	useParams, Redirect
} from "react-router-dom";
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PageHeader from '../../components/pageheader';
import CampusInfo from './components/CampusInfo';
import style from '../../styling/Panels.module.css';
import DeleteCampus from './components/DeleteCampus';
import AssignClassroom from './components/AssignClassroom';
// import VideoModal from './components/VideoModal'
// import StartCameras from './components/StartCameras';

const GET_CLASSROOM = gql`
  	query campus($id: ID!) {
		campus(id: $id) {
			id
            name
            classrooms{
                id
                name
            }
		}
	}
`;

export default function App() {
	let { id } = useParams();
	const { data, loading, error } = useQuery(
		GET_CLASSROOM,
        { variables: { id: id }, 
        pollInterval: 1000 * 2}
	);

	if (loading) {
		return (
			<div>
				<PageHeader title="Loading" />
			</div>
		);
	}
	else if (error) {
		return (
			<Redirect to='/login' />
		)
	}
	else {
		return (
			<div>
				<PageHeader title={ "Campus - " + data.campus.name } />

				<div className={ style.panel_row }>
					<div className={ style.panel + ' ' + style.panel_single }>
						<CampusInfo campus={ data.campus } classrooms={ data.campus.classrooms }/>
					</div>
				</div>
				{
					!data.name ? 
						<div className={ style.panel_row }>
							<div className={ style.panel + ' ' + style.panel_double }>
								<AssignClassroom id={ data.campus.id } />
							</div>
							{/* <div className={ style.panel + ' ' + style.panel_double }> */}
								{/* <StartCameras classroom={ data.classroom }/> */}
							{/* </div> */}
                        </div> 
                        :
						<div className={ style.panel + ' ' + style.panel_double }>
							{/* <VideoModal url={ data.classroom.nbot.url } /> */}
						</div>
				}
				<div>
					<DeleteCampus campus={ data.campus } />
				</div>
			</div>
		)
	}
}