import React from 'react';
import './pages/nbot/index.jsx';
import Header from './layout/header';
import Footer from './layout/footer';
import Content from './layout/content';
import Login from './pages/login/login'
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const login = {
	path: '/login',
	exact: true,
	component: () => <Login />
}
const login2 = {
	path: '/',
	exact: true,
	component:() => <Login />
}
const page = {
	path: '/',
	exact: false,
	component:() => <Content />
}

function App() {
	return (
		<div>
			<BrowserRouter>
				<div style={{ width:"80%", margin: "auto" }}>
					<Header />
					<Switch>
						<Route
							key='0'
							path={ login.path }
							exact={ login.exact }
							children={ login.component }
						/>
						<Route
							key='0'
							path={ login2.path }
							exact={ login2.exact }
							children={ login2.component }
						/>
						<Route
							key='-1'
							path={ page.path }
							exact={ page.exact }
							children={ page.component }
						/>
					</Switch>
					<Footer />
				</div>
			</BrowserRouter>
			<ToastContainer autoClose={ 2500 } />
		</div>
	);
    
}

export default App;
